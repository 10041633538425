// Packages
import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { ContractsListRequest, HotelReportDataRequest } from '../../../../requests';

// Utils
import { auth, contractsCache } from '../../../../atoms';
import { Loader, TableLite } from '../../../../components';
import { constants, decodeString, authenticationErrorHandle } from '../../../../utils';

export default function CateringReportTable() {

  const CATERING_REPORT_CUSTOM_HEADERS = {
    fsr_id: "FSR Id",
    at: "At",
    crew: "Total Crew",
    pax: "Total PAX",
    catering_type: "Catering Type"        
  };

  const downloadRef = useRef(null);
  const [authState, _authState] = useAtom(auth);
  const [contract, _contract] = useAtom(contractsCache);
  const [hotelRequests, _hotelRequests] = useState([]);
  const [selectedContract, _selectedContract] = useState('');
  const [selectedMonth, _selectedMonth] = useState('');
  const [selectedYear, _selectedYear] = useState(dayjs().format('YYYY'));  
  const [isLoading, _isLoading] = useState(false);


  // Fetch contracts
  useEffect(() => {
    if (authState)
      getContract();
  }, [authState]);

  // Fetch the catering requests based on filters
  useEffect(() => {
    if (authState && selectedContract && selectedMonth && selectedYear)
      getCateringData();
  }, [authState, selectedContract, selectedMonth, selectedYear]);

  const getContract = () => {
    const token = decodeString(authState);
    ContractsListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _contract({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    })
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contracts' });
        }
      )
  };

  const getCateringData = () => {
    _isLoading(true);
    const token = decodeString(authState);
    const contractId = parseInt(selectedContract);
    const data = JSON.stringify({
      month: parseInt(selectedMonth),
      year: parseInt(selectedYear)
    });
    HotelReportDataRequest(token, contractId, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData?.approve_request_legs || nonPaginatedData?.standalone_hotel_requests) {
          _isLoading(false);
          let hotelRequestsArray = nonPaginatedData?.approve_request_legs?.map((item) => (
            {
              fsr_id: item?.request || '-',
              hotel_id: '-',
              aircraft: item?.aircraft?.tailnumber,
              departure: `${item?.departure_airport_icao}/${item?.departure_airport_iata}`,
              destination: `${item?.destination_airport_icao}/${item?.destination_airport_iata}`,
              country: item?.destination_airport_country || '-',
              city: item?.destination_airport_city || '-',
              no_of_rooms: Number(item?.approve_request_leg_hotel?.length),
              checkin_date: item?.approve_request_leg_hotel?.find(H => H?.checkInDateTime)?.checkInDateTime?.replace(/[TZ]/g, ' '),
              checkout_date: item?.approve_request_leg_hotel?.find(H => H?.checkOutDateTime)?.checkOutDateTime?.replace(/[TZ]/g, ' ')
            }
          ));
          const standAloneHotelRequests = nonPaginatedData?.standalone_hotel_requests?.map((item) => (
            {
              fsr_id: '-',
              hotel_id: item?.hotel_request,
              aircraft: item?.ACFT_REG,
              departure: `-`,
              destination: `-`,
              country: item?.country || '-',
              city: item?.city || '-',
              no_of_rooms: item?.no_of_rooms,
              checkin_date: item?.checkin_date?.replace(/[TZ]/g, ' '),
              checkout_date: item?.checkout_date?.replace(/[TZ]/g, ' ')
            }
          ));
          hotelRequestsArray = hotelRequestsArray?.concat(standAloneHotelRequests);
          _hotelRequests(hotelRequestsArray);
        } else {

          throw "Request Failed";
        }
      })
      .catch(err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch hotel requests" });
      })
  };

  const handleContractChange = (e) => {
    _selectedContract(e.target.value);
  };

  const handleMonthChange = (e) => {
    _selectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    _selectedYear(e.target.value);
  };


  // *********** Render Functions ***********

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const NO_CATERING_BOOKINGS = () => (
    <div className='small-top-margin'> No items Found </div>
  );

  const CATERING_REQUEST_FILTER_FIELDS = () => (
    <div>
      <div className='w3-row-padding w3-stretch'>
        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Contract </label>
          <select
            id="contract"
            value={selectedContract}
            onChange={handleContractChange}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Contract </option>
            {contract &&
              contract?.data &&
              contract?.data
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='month'> Month </label>
          <select
            id='month'
            value={selectedMonth}
            onChange={handleMonthChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Month </option>
            {constants?.MONTHS?.map((option, index) => <option key={option} value={index + 1}> {option} </option>)}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='year'> Year </label>
          <select
            id='year'
            name='year'
            value={selectedYear}
            onChange={handleYearChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Year </option>
            {Array.from({ length: parseInt(dayjs().year()) - 2024 + 1 }, (_, i) => i + 2024).map((item) => (
              <option value={item} key={item}> {item} </option>
            ))
            }
          </select>
        </div>

        <div className='w-full w3-row-padding'>
          <div className='w-full flex small-top-margin justify-end'>
            <button ref={downloadRef} disabled={!selectedContract || !selectedMonth || !selectedYear} className='w3-btn bg-primary-blue w3-text-white small-right-margin'> Download Excel </button>
          </div>
        </div>
      </div>
    </div>
  );

  const CATERING_REPORT_PRE_EXPORT_VIEW = () => (
    <div className='w3-margin-top'>
      <TableLite
        showActions={false}
        downloadable={true}
        cellStyle={{ fontSize: "0.8em" }}
        data={hotelRequests || []}
        downloadCsvButtonRef={downloadRef}
        headers={Object.keys(CATERING_REPORT_CUSTOM_HEADERS)}
        customHeaders={CATERING_REPORT_CUSTOM_HEADERS}
        fileName={`${contract && contract?.data
          ?.find(C => C?.id == selectedContract)?.name} catering`
        }
      />
    </div>
  );

  return (
    <div id="catering-report">
      {CATERING_REQUEST_FILTER_FIELDS()}
      {isLoading ? LOADER() : CATERING_REPORT_PRE_EXPORT_VIEW()}
    </div>
  );

};