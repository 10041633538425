// Packages
import React, { useState, useEffect, Fragment } from 'react'
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';

// APIs
import { ContractsListRequest, InvoicePdfDataRequest, AirbaseListRequest } from '../../../../requests';

// Utils
import { airbasesCache, auth, contractsCache } from '../../../../atoms';
import { Loader, TableLite } from '../../../../components';
import { constants, decodeString, authenticationErrorHandle, setInvoiceReportPdfContent, formatPrice, ignoreTimeZone } from '../../../../utils';

export default function InvoicePdfViewer() {
  const DEFAULT_LANGUAGE = 'en';
  const DEFAULT_LETTER_HEAD = `His excellency, Base Commander\nAssalamoalaikum \nProviding operation and technical support services for a number of aircrafts of various types inside and outside the Kingdom, we attach to you the following financial claims`;

  const TRANSLATE = {
    'en': {
      "Language": "Language / لغة",
      "Month": "Month",
      "Count": "Count",
      "Total Amount": "Total Amount",
      "Service": "Service",
      "Aircraft": "Aircraft",
      "Service Type": "Service Type",
      "Unit Cost": "Unit Cost",
      "Total Cost": "Total Cost",
      "Report for": "Report for",
      "Invoice Number": "Invoice Number",
      "Grand Total": "Grand Total",
      "Additional services": "Additional services",
      "Letter Head": "Letter Head",
      "Please accept my highest regards and appreciation": "Please accept my highest regards and appreciation",
      "CEO Saudia Private": "CEO Saudia Private",
      "Fahad Bin Ibrahim Al Jarboa": "Fahad Bin Ibrahim Al Jarboa",
      "5% administrative fees": "5% administrative fees",
      "Total including administrative fees": "Total including administrative fees",
      "15% VAT": "15% VAT",
      "0% VAT": "0% VAT",
      "Total including Tax": "Total including Tax",
      "Flight Hours": "Flight Hours",
      "Discount": "Discount",
      "Minutes": "Minutes",
      "Block Hour Cost": "Block Hour Cost",
      "Total Block Hours": "Total Block Hours",
      "Departure From": "Departure",
      "Arrival At": "Arrival",
      "Departure Date": "Departure Date",
      "Arrival Date": "Arrival Date",
      "Total Minutes": "Total Minutes"
    },
    'ar': {
      "Language": "Language / لغة",
      "Service Type": 'نوع الخدمة',
      "Month": "الشهر",
      "Count": "العدد",
      "Service": "خدمة",
      "Total Amount": "المجموع",
      "Aircraft": "الطائرات",
      "Unit Cost": "السعر",
      "Total Cost": "المجموع",
      "Report for": "تقرير الطائرة",
      "Invoice Number": "رقم الفاتورة",
      "Grand Total": "المجموع الكلي",
      "Additional services": "خدمات إضافية",
      "Letter Head": "ترويسة",
      "Please accept my highest regards and appreciation": "وتقبلوا سعادتكم فائق التحية والتقدير",
      "CEO Saudia Private": "الرئيس التنفيذي للطيران السعودي الخاص",
      "Fahad Bin Ibrahim Al Jarboa": "فهد بن ابراهيم الجربوع",
      "5% administrative fees": "الأتعاب الادارية 5%",
      "Total including administrative fees": "مع الأتعاب الادارية",
      "15% VAT": "15% VAT",
      "0% VAT": "0% VAT",
      "Total including Tax": "المجموع الكلي",
      "Flight Hours": "ساعات طيران",
      "Discount": "الخصم",
      "Minutes": "عدد الدقائق",
      "Block Hour Cost": "قيمة الساعة",
      "Total Block Hours": "عدد ساعات الطيران",
      "Departure From": "مطار المغادرة",
      "Arrival At": "مطار الوجهة",
      "Departure Date": "تاريخ المغادرة",
      "Arrival Date": "تاريخ الهبوط",
      "Total Minutes": "إجمالي عدد الدقائق"
    }
  }
  const [authState, _authState] = useAtom(auth);
  const [contract, _contract] = useAtom(contractsCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [selectedAirbase, _selectedAirbase] = useState('');
  const [contractCostPlusInvoicesItems, _contractCostPlusInvoicesItems] = useState({});
  const [contractBlockHourInvoicesItems, _contractBlockHourInvoicesItems] = useState({});
  const [feesAndTaxes, _feesAndTaxes] = useState({});
  const [letterHeadContent, _letterHeadContent] = useState(DEFAULT_LETTER_HEAD);
  const [reportLanguage, _reportLanguage] = useState(DEFAULT_LANGUAGE);
  const [selectedContract, _selectedContract] = useState('');
  const [selectedMonth, _selectedMonth] = useState('');
  const [selectedYear, _selectedYear] = useState(dayjs().format('YYYY'));
  const [invoiceType, _invoiceType] = useState('cost-plus');
  const [isLoading, _isLoading] = useState(false);


  // Fetch contracts
  useEffect(() => {
    if (authState) {
      getContract();
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000)
        getAirbases()
    }
  }, [authState]);

  // Fetch the invoice items based on filters
  useEffect(() => {
    if (authState && selectedContract && selectedMonth && selectedYear)
      getContractInvoiceItems();
  }, [authState, selectedContract, selectedAirbase, selectedMonth, selectedYear]);

  // Set the VAT and service fees based on total amount of total Cost Plus
  useEffect(() => {
    if (contractCostPlusInvoicesItems?.total) {
      const ServiceFees = parseInt(contractCostPlusInvoicesItems?.total * 0.05)?.toFixed(3);
      const ServiceFeesPlusAmount = parseInt(ServiceFees) + Number(contractCostPlusInvoicesItems?.total);
      const VAT = (parseInt(ServiceFeesPlusAmount) * 0.150)?.toFixed(3);
      const VATplusTotal = (parseFloat(VAT) + parseFloat(ServiceFeesPlusAmount))?.toFixed(3);
      _feesAndTaxes({
        ServiceFees,
        ServiceFeesPlusAmount,
        VAT,
        VATplusTotal
      });
    }
  }, [contractCostPlusInvoicesItems?.total]);

  const getContractInvoiceItems = () => {
    _isLoading(true);
    const token = decodeString(authState);
    const contractId = parseInt(selectedContract);
    /* Contract number will be (YYYY).join(MM).join(contract_id)*/
    const createdContractNo = dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('YYYYMM') + selectedContract;
    const data = JSON.stringify({
      month: parseInt(selectedMonth),
      year: parseInt(selectedYear),
      airbase_ids: selectedAirbase ? [Number(selectedAirbase)] : [],
      invoice_no: createdContractNo
    });
    InvoicePdfDataRequest(token, contractId, data)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData?.invoice_service_data) {
          _isLoading(false);
          _contractCostPlusInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.invoice_service_data?.service_data,
            total: nonPaginatedData?.invoice_service_data?.total_stats?.net_total_cost || 0
          }));
          _contractBlockHourInvoicesItems(old => ({
            invoice_items: nonPaginatedData?.block_hour_usage?.block_hour_data,
            total: nonPaginatedData?.block_hour_usage?.total_stats?.total_blockhours || 0,
            total_cost: nonPaginatedData?.block_hour_usage?.total_stats?.net_total_cost || 0
          }));
        } else {
          _contractCostPlusInvoicesItems({});
          _contractBlockHourInvoicesItems({});
          throw "Request Failed";
        }
      })
      .catch(err => {
        _isLoading(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch contract invoices" });
      })
  };

  const getContract = () => {
    const token = decodeString(authState);
    ContractsListRequest(token).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _contract({
          data: [...data.results],
          created: Date.now()
        });
      } else {
        throw 'Request Failed';
      }
    })
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contracts' });
        }
      )
  };

  const getAirbases = () => {
    _isLoading(true);
    const token = decodeString(authState);
    AirbaseListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((nonPaginatedData) => {
        if (constants?.LOGOUTERRORTYPES?.includes(nonPaginatedData?.errorCodes)) return;
        if (nonPaginatedData) {
          // Keep server data in cache with current time
          _airbases({
            data: [...nonPaginatedData],
            created: Date.now(),
          });
          _isLoading(false);
        } else {
          throw "Request Failed";
        }
      })
      .catch((err) => {
        console.error(err);
        _isLoading(false);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
      });
  };

  const handleLetterHeadContentChange = (e) => {
    _letterHeadContent(e.target.value);
  };

  const handleLanguageChange = (e) => {
    _reportLanguage(e.target.value);
  };

  const handleContractChange = (e) => {
    _selectedContract(e.target.value);
    _selectedAirbase('');
  };

  const handleMonthChange = (e) => {
    _selectedMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    _selectedYear(e.target.value);
  };

  const handleAirbaseChange = (e) => {
    _selectedAirbase(e.target.value);
  };

  const handleInvoiceTypeChange = (value) => {
    _invoiceType(value);
  };

  const handleDirectRenderPdf = () => {
    document.getElementById('letter-head-content-preview').innerHTML = letterHeadContent;
    const contractName = (contract && contract?.data?.find(C => C?.id == selectedContract)?.name);
    const reportDate = dayjs(selectedMonth + '-01-' + selectedYear)?.format('MMM YYYY');
    const fileName = 'Invoice ' + contractName + ` (${reportDate})`;
    let InvoiceContent = document.querySelector("#invoice-pdf-container")?.innerHTML || '';
    InvoiceContent = `<div class="${reportLanguage}"> ${InvoiceContent} </div>`;
    const PDFContent = setInvoiceReportPdfContent(InvoiceContent, fileName);
    const printWindow = window.open('', '', 'height=1024,width=900,toolbar=no,menubar=no');
    printWindow.document.write(PDFContent);
    printWindow.document.close();
    printWindow.print();
  };

  const handleSendToZatca = (e) => {
    if (!selectedContract || !selectedMonth) return;
    const data = JSON.stringify(
      {
        contract_id: Number(selectedContract),
        month: Number(selectedMonth),
        year: Number(selectedYear)
      }
    );
    // handleExportInvoice(data);
  }

  // *********** Render Functions ***********

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const NO_INVOICE_ITEMS = () => (
    <div className='small-top-margin'> No invoice items Found </div>
  );

  const CEO_SIGNATURE_SECTION = () => (
    <div className='signature-section'>
      <br />
      <br />
      <p> {TRANSLATE[reportLanguage]['Please accept my highest regards and appreciation']} </p>
      <br />
      <br />
      <br />
      <br />
      <br />    
      <p>
        _____________________________
      </p>
      <p>
        <strong> {TRANSLATE[reportLanguage]['Fahad Bin Ibrahim Al Jarboa']}
          <br /> {TRANSLATE[reportLanguage]['CEO Saudia Private']}
        </strong>
      </p>
    </div>
  );

  const INVOICE_FILTER_FIELDS = () => (
    <div>
      <div className='w3-row-padding w3-stretch'>
        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Contract </label>
          <select
            id="contract"
            value={selectedContract}
            onChange={handleContractChange}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Contract </option>
            {contract &&
              contract?.data &&
              contract?.data
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='contract'> Airbase </label>
          <select
            id="airbase"
            value={selectedAirbase}
            onChange={handleAirbaseChange}
            className='w3-input w3-white small-top-margin w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""}> All Airbases </option>
            {airbases &&
              airbases?.data &&
              airbases?.data?.filter((Ab) => (
                Ab.contract == selectedContract
              ))
                ?.map((Ab, index) => (
                  <option value={Ab.id} key={index}> {Ab.name} </option>
                ))}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='month'> Month </label>
          <select
            id='month'
            value={selectedMonth}
            onChange={handleMonthChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Month </option>
            {constants?.MONTHS?.map((option, index) => <option key={option} value={index + 1}> {option} </option>)}
          </select>
        </div>

        <div className="w3-col s12 m4">
          <label className='w3-medium' htmlFor='year'> Year </label>
          <select
            id='year'
            name='year'
            value={selectedYear}
            onChange={handleYearChange}
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
          >
            <option value={""} disabled> Select Year </option>
            {Array.from({ length: parseInt(dayjs().year()) - 2024 + 1 }, (_, i) => i + 2024).map((item) => (
              <option value={item} key={item}> {item} </option>
            ))
            }
          </select>
        </div>

        <div className='w-full w3-row-padding'>
          <div className='w-full flex small-top-margin justify-end'>
            <button disabled={!selectedContract || !selectedMonth || !selectedYear} onClick={handleDirectRenderPdf} className='w3-btn bg-primary-blue w3-text-white small-right-margin'> Generate PDF </button>
            <button disabled={!selectedContract || !selectedMonth || !selectedYear} onClick={handleSendToZatca} className='w3-btn bg-primary-blue w3-text-white'> Export to ZATCA </button>
          </div>
        </div>

        <div className="w3-col m12 w3-bar w3-margin-top" >
          <div
            onClick={e => handleInvoiceTypeChange('cost-plus')}
            className={`w3-col w3-half w3-button ${invoiceType === "cost-plus" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
          >
            Cost Plus
          </div>
          <div
            onClick={e => handleInvoiceTypeChange('block-hours')}
            className={`w3-col w3-half w3-button ${invoiceType === "block-hours" ? 'bg-primary-blue w3-text-white' : 'w3-light-grey'}`}
          >
            Block Hours
          </div>
        </div>
      </div>
    </div>
  );

  const AIRCRAFT_COST_INVOICE_ITEMS = (item, index) => {
    const COST_INVOICE_CUSTOM_HEADERS = {
      "service_type": TRANSLATE[reportLanguage]['Service Type'],
      "service": TRANSLATE[reportLanguage]['Service'],
      "month": TRANSLATE[reportLanguage]['Month'],
      "aircraft": TRANSLATE[reportLanguage]['Aircraft'],
      "count": TRANSLATE[reportLanguage]['Count'],
      "unit_cost": TRANSLATE[reportLanguage]['Unit Cost'],
      "total_cost": TRANSLATE[reportLanguage]['Total Cost']
    };

    const CUSTOM_RENDER_TABLE_CELL = {
      month: (row) => dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('MMM YYYY'),
      aircraft: (row) => item?.aircraft_tail_number,
      unit_cost: (row) => formatPrice(Number(row?.unit_cost)),
      total_cost: (row) => formatPrice(Number(row?.total_cost)),
    };

    const AIRCRAFT_TAILNUMBER_HEADER = () => (
      <div className='aircraft-section-header'>
        <div className="gap"> </div>
        {/* <div className='spa-logo'> </div> */}
        <div>
          {`${TRANSLATE[reportLanguage]["Report for"]} ${item?.aircraft_tail_number}`}
        </div>
      </div>
    );

    const AIRCRAFT_TABLE_COST_LIST_ITEMS = () => (
      <TableLite
        showActions={false}
        data={item?.services || []}
        headers={Object.keys(COST_INVOICE_CUSTOM_HEADERS)}
        customHeaders={COST_INVOICE_CUSTOM_HEADERS}
        customRenderCell={CUSTOM_RENDER_TABLE_CELL}
        cellStyle={{ fontSize: "0.8em" }}
      />
    );

    const AIRCRAFT_COST_LIST_GRAND_TOTAL_AMOUNT = () => (
      <table className='w3-table w3-bordered'>
        <tbody>
          <tr>
            <td>
              <div> <strong> {TRANSLATE[reportLanguage]['Total Amount']} </strong> </div>
            </td>
            <td className='total-amount'>
              <div> <strong> {item?.services?.reduce((acc, service) => acc += service?.total_cost, 0)?.toFixed(3)} </strong> </div>
            </td>
          </tr>
        </tbody>
      </table>
    );

    return (
      <div key={index} className="aircraft-wise-section" style={{ pageBreakAfter: 'always' }}>
        {AIRCRAFT_TAILNUMBER_HEADER()}
        {AIRCRAFT_TABLE_COST_LIST_ITEMS()}
        {AIRCRAFT_COST_LIST_GRAND_TOTAL_AMOUNT()}
      </div>
    )
  };

  const AIRCRAFT_BLOCK_HOUR_ITEMS = (item, index) => {
    const BLOCK_HOUR_CUSTOM_HEADERS = {
      "aircraft_tail_number": TRANSLATE[reportLanguage]['Aircraft'],
      "departure_airport_icao": TRANSLATE[reportLanguage]['Departure From'],
      "departure_date_time": TRANSLATE[reportLanguage]['Departure Date'],
      "arrival_airport_icao": TRANSLATE[reportLanguage]['Arrival At'],
      "arrival_date_time": TRANSLATE[reportLanguage]['Arrival Date'],
      "total_block_hours": TRANSLATE[reportLanguage]['Minutes']
    };

    const CUSTOM_RENDER_TABLE_CELL = {
      departure_airport_icao: (row) => row?.departure_airport_icao + '/' + row?.departure_airport_iata,
      arrival_airport_icao: (row) => row?.arrival_airport_icao + '/' + row?.arrival_airport_iata,
      departure_date_time: (row) => dayjs(ignoreTimeZone(row?.departure_date_time))?.format('YYYY-MM-DD HH:mm'),
      arrival_date_time: (row) => dayjs(ignoreTimeZone(row?.arrival_date_time))?.format('YYYY-MM-DD HH:mm'),
      total_block_hours: (row) => (Number(row?.total_block_hours) * 60)?.toFixed(2)
    };

    const AIRCRAFT_TAILNUMBER_HEADER = () => (
      <div className='aircraft-section-header'>
        <div className="gap"> </div>
        {/* <div className='spa-logo'> </div> */}
        <div>
          {`${TRANSLATE[reportLanguage]["Report for"]} ${item?.aircraft_tail_number}`}
        </div>
      </div>
    );

    const AIRCRAFT_TABLE_BLOCK_HOUR_ITEMS = () => (
      <TableLite
        showActions={false}
        data={item?.block_hour_items || []}
        headers={Object.keys(BLOCK_HOUR_CUSTOM_HEADERS)}
        customHeaders={BLOCK_HOUR_CUSTOM_HEADERS}
        customRenderCell={CUSTOM_RENDER_TABLE_CELL}
        cellStyle={{ fontSize: "0.8em" }}
      />
    );

    const AIRCRAFT_BLOCK_HOUR_GRAND_TOTAL_AMOUNT = () => (
      <table className='w3-table w3-bordered'>
        <tbody>
          <tr>
            <td>
              <div> <strong> {TRANSLATE[reportLanguage]['Total Minutes']} </strong> </div>
            </td>
            <td className='total-amount'>
              <div> <strong> {(item?.block_hour_items?.reduce((acc, blockHrs) => acc += blockHrs?.total_block_hours, 0) * 60)?.toFixed(3)} </strong> </div>
            </td>
          </tr>
        </tbody>
      </table>
    );

    return (
      <div key={index} className="aircraft-wise-section" style={{ pageBreakAfter: 'always' }}>
        {AIRCRAFT_TAILNUMBER_HEADER()}
        {AIRCRAFT_TABLE_BLOCK_HOUR_ITEMS()}
        {AIRCRAFT_BLOCK_HOUR_GRAND_TOTAL_AMOUNT()}
      </div>
    )
  };

  const COVER_PAGE_COST_PLUS_INVOICE_SUMMARY_TABLE = () => (
    <table className='w3-table'>
      <tbody>
        <tr>
          <td> {TRANSLATE[reportLanguage]['Invoice Number']} </td>
          <td> {TRANSLATE[reportLanguage]['Service Type']} </td>
          <td> {TRANSLATE[reportLanguage]['Month']} </td>
          <td> {TRANSLATE[reportLanguage]['Grand Total']} </td>
        </tr>
        <tr>
          <td>{`C${dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('YYYYMM') + selectedContract}`}</td>
          <td> {TRANSLATE[reportLanguage]['Additional services']} </td>
          <td> {dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('MMM YYYY')} </td>
          <td> {formatPrice(Number(feesAndTaxes?.VATplusTotal))} </td>
        </tr>
      </tbody>
    </table>
  );

  const COVER_PAGE_BLOCK_HOUR_INVOICE_SUMMARY_TABLE = () => (
    <table className='w3-table'>
      <tbody>
        <tr>
          <td> {TRANSLATE[reportLanguage]['Invoice Number']} </td>
          <td> {TRANSLATE[reportLanguage]['Service Type']} </td>
          <td> {TRANSLATE[reportLanguage]['Month']} </td>
          <td> {TRANSLATE[reportLanguage]['Grand Total']} </td>
        </tr>
        <tr>
          <td>{`B${dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('YYYYMM') + selectedContract}`}</td>
          <td> {TRANSLATE[reportLanguage]['Flight Hours']} </td>
          <td> {dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('MMM YYYY')} </td>
          <td> {formatPrice(Number(contractBlockHourInvoicesItems?.total_cost))} </td>
        </tr>
      </tbody>
    </table>
  );

  const INVOICE_LETTER_HEAD = () => (
    <div style={{ pageBreakAfter: 'always' }}>
      <div className='invoice-report-hidden-section'>
        <h4> {TRANSLATE[reportLanguage]['Language']} </h4>
        <select
          id='language-select'
          className='w3-input h-14 w-36 w3-border small-top-margin w3-round'
          onChange={handleLanguageChange}
          value={reportLanguage}
        >
          <option value="en"> English </option>
          <option value="ar"> عربی </option>
        </select>
      </div>
      <div className="gap"> </div>
      {/* <div className="spa-logo"> </div> */}
      <div className='invoice-report-hidden-section'>
        <h4> {TRANSLATE[reportLanguage]['Letter Head']} </h4>
        <textarea
          id="letter-head-content-input"
          style={{ fieldSizing: 'content' }}
          value={letterHeadContent}
          onChange={handleLetterHeadContentChange}
          className='w3-input w3-round resize-vertical-only w3-margin-top w3-margin-bottom w3-border'
        />
      </div>
      <div
        style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
        className='invoice-report-preview-hidden-section'
        id="letter-head-content-preview"
      >
      </div>
      <div className='invoice-report-preview-hidden-section'>
        {invoiceType === 'cost-plus'
          ? COVER_PAGE_COST_PLUS_INVOICE_SUMMARY_TABLE()
          : COVER_PAGE_BLOCK_HOUR_INVOICE_SUMMARY_TABLE()
        }
        {CEO_SIGNATURE_SECTION()}
      </div>
    </div>
  );

  const COST_INVOICE_TOTAL_AMOUNT_AND_TAXES_SUMMARY = () => (
    <div className="taxes-summary-section" style={{ pageBreakAfter: 'always' }}>
      {/* <div className="spa-logo"> </div> */}
      <div className="gap"> </div>
      <table className='w3-table w3-bordered'>
        <thead>
          <tr>
            <th> {TRANSLATE[reportLanguage]['Month']} </th>
            <th> {TRANSLATE[reportLanguage]['Aircraft']} </th>
            <th> {TRANSLATE[reportLanguage]['Total Amount']} </th>
          </tr>
        </thead>
        <tbody>
          {contractCostPlusInvoicesItems &&
            contractCostPlusInvoicesItems?.invoice_items?.map((item, index) => (
              <tr key={index}>
                <td> {dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('MMM YYYY')} </td>
                <td> {item?.aircraft_tail_number} </td>
                <td> {item?.services?.reduce((acc, service) => acc += service?.total_cost, 0)?.toFixed(3)} </td>
              </tr>
            ))
          }
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['Total Amount']} </strong> </td>
            <td> <strong>{formatPrice(Number(contractCostPlusInvoicesItems?.total))}</strong> </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['5% administrative fees']} </strong> </td>
            <td> {formatPrice(Number(feesAndTaxes?.ServiceFees))} </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['Total including administrative fees']} </strong> </td>
            <td> {formatPrice(Number(feesAndTaxes?.ServiceFeesPlusAmount))} </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['15% VAT']} </strong> </td>
            <td> {formatPrice(Number(feesAndTaxes?.VAT))} </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['Total including Tax']} </strong> </td>
            <td> {formatPrice(Number(feesAndTaxes?.VATplusTotal))} </td>
          </tr>
        </tbody>
      </table>
      <p className='invoice-report-page-2-footer'>
        <br />
        Saudia Private - Tel, +966 12 9200 13310 Fax. 966 12 686 1017
        <br />
        CR. 4030182668 - P.O. Box 620 - Jeddah 21231, Kingdom of Saudi Arabia
        <br />
        www.saudiaprivate.sa
      </p>
    </div>
  );

  const CONTRACT_AIRCRAFT_WISE_COST_ITEMS_TABLE = () => (
    <div>
      {contractCostPlusInvoicesItems &&
        contractCostPlusInvoicesItems?.invoice_items?.map((item, index) => AIRCRAFT_COST_INVOICE_ITEMS(item, index)
        )
      }
    </div>
  );

  const BLOCK_HOURS_TOTAL_AMOUNT_AND_TAXES_SUMMARY = () => (
    <div className="taxes-summary-section" style={{ pageBreakAfter: 'always' }}>
      {/* <div className="spa-logo"> </div> */}
      <div className="gap"> </div>
      <table className='w3-table w3-bordered'>
        <thead>
          <tr>
            <th> {TRANSLATE[reportLanguage]['Service Type']} </th>
            <th> {TRANSLATE[reportLanguage]['Month']} </th>
            <th> {TRANSLATE[reportLanguage]['Aircraft']} </th>
            <th> {TRANSLATE[reportLanguage]['Total Block Hours']} </th>
            <th> {TRANSLATE[reportLanguage]['Total Minutes']} </th>
            <th> {TRANSLATE[reportLanguage]['Total Amount']} </th>
          </tr>
        </thead>
        <tbody>
          {contractBlockHourInvoicesItems &&
            contractBlockHourInvoicesItems?.invoice_items?.map((item, index) => (
              <tr key={index}>
                <td>{TRANSLATE[reportLanguage]['Flight Hours']}</td>
                <td> {dayjs(`${selectedMonth}-01-${selectedYear}`)?.format('MMM YYYY')} </td>
                <td> {item?.aircraft_tail_number} </td>
                <td> {item?.block_hour_items?.reduce((acc, blockHrs) => acc += blockHrs?.total_block_hours, 0)?.toFixed(3)} </td>
                <td> {(item?.block_hour_items?.reduce((acc, blockHrs) => acc += blockHrs?.total_block_hours, 0) * 60)?.toFixed(3)} </td>
                <td> {formatPrice(item?.block_hour_items?.reduce((acc, blockHrs) => acc += blockHrs?.total_cost, 0))} </td>
              </tr>
            ))
          }
          <tr>
            <td colSpan={2}> <strong>{TRANSLATE[reportLanguage]['Total Block Hours']}</strong> </td>
            <td> <strong>{Number(contractBlockHourInvoicesItems?.total)}</strong> </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['Total Amount']} </strong> </td>
            <td> <strong>{formatPrice(Number(contractBlockHourInvoicesItems?.total_cost))}</strong> </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['0% VAT']} </strong> </td>
            <td> <strong>{formatPrice(Number(contractBlockHourInvoicesItems?.total_cost))}</strong> </td>
          </tr>
          <tr>
            <td colSpan={2}> <strong> {TRANSLATE[reportLanguage]['Total including Tax']} </strong> </td>
            <td> <strong>{formatPrice(Number(contractBlockHourInvoicesItems?.total_cost))}</strong> </td>
          </tr>
        </tbody>
      </table>
      <p className='invoice-report-page-2-footer'>
        <br />
        Saudia Private - Tel, +966 12 9200 13310 Fax. 966 12 686 1017
        <br />
        CR. 4030182668 - P.O. Box 620 - Jeddah 21231, Kingdom of Saudi Arabia
        <br />
        www.saudiaprivate.sa
      </p>
    </div>
  );

  const CONTRACT_AIRCRAFT_WISE_BLOCK_HOUR_ITEMS_TABLE = () => (
    <div>
      {contractBlockHourInvoicesItems &&
        contractBlockHourInvoicesItems?.invoice_items?.map((item, index) => AIRCRAFT_BLOCK_HOUR_ITEMS(item, index)
        )
      }
    </div>
  );

  const REPORT_TYPE = () => (
    invoiceType === 'cost-plus'
      ?
      <Fragment>
        {COST_INVOICE_TOTAL_AMOUNT_AND_TAXES_SUMMARY()}
        {CONTRACT_AIRCRAFT_WISE_COST_ITEMS_TABLE()}
      </Fragment>
      : <Fragment>
        {BLOCK_HOURS_TOTAL_AMOUNT_AND_TAXES_SUMMARY()}
        {CONTRACT_AIRCRAFT_WISE_BLOCK_HOUR_ITEMS_TABLE()}
      </Fragment>
  );

  const COST_INVOICE_PRE_EXPORT_PDF_VIEW = () => (
    <div id='invoice-pdf-container' className={reportLanguage}>
      {contractCostPlusInvoicesItems &&
        contractCostPlusInvoicesItems?.invoice_items?.length
        ?
        <Fragment>
          {INVOICE_LETTER_HEAD()}
          {REPORT_TYPE()}
        </Fragment>
        : NO_INVOICE_ITEMS()
      }
    </div>
  );

  return (
    <div id="invoices-pdf">
      {INVOICE_FILTER_FIELDS()}
      {isLoading ? LOADER() : COST_INVOICE_PRE_EXPORT_PDF_VIEW()}
    </div>
  )

};