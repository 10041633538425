
// Packages
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Store } from 'react-notifications-component';
import { useNavigate, useParams } from 'react-router-dom';

// APIs
import { AirbaseListRequest, BlockHourInvoiceDetailsRequest, BlockHourInvoiceEditRequest, FleetCreateRequest, FleetEditRequest, FleetListRequest } from '../../../requests';

// Utils
import { auth, airbasesCache, fleetCache, loggedUserInfoCache } from '../../../atoms';
import { constants, decodeString, authenticationErrorHandle, ignoreTimeZone } from '../../../utils';
import { Loader } from '../../../components';
import dayjs from 'dayjs';

function BlockHourInvoicesForm() {
  const { invoiceId } = useParams();
  const Navigate = useNavigate();

  const INITIAL_FORM = {
    approve_request_id: '',
    contract_id: '',
    status: 'Draft',
    request_id: '',
    block_hour_invoice_item: []
  };

  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(invoiceId ? true : false);
  const [form, _form] = useState({ ...INITIAL_FORM });
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [fleet, _fleet] = useAtom(fleetCache);
  const [airbases, _airbases] = useAtom(airbasesCache);

  useEffect(() => {
    if (invoiceId && authState) {
      getBlockHourInvoiceDetails();
    }
  }, [authState, invoiceId]);

  const getFleet = () => {
    const token = decodeString(authState);
    FleetListRequest(token)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if (data && data.results) {
          // Keep server data in cache with current time
          _fleet({
            data: [...data.results],
            created: Date.now(),
          });
        } else {
          throw 'Request Failed';
        }
      })
      .catch((err) => {
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch fleet' });
      });
  };

  const getBlockHourInvoiceDetails = () => {
    _isLoading(true);
    const token = decodeString(authState);
    BlockHourInvoiceDetailsRequest(token, invoiceId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        _form(data);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          _isLoading(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch blockhour details' });
        }
      )
  };

  // *********** Handlers ***********

  const handleBlockHourInvoiceFieldChange = (e, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item[e.target.name] = e.target.value;
          }
          return item;
        })
    }));
  };

  const handleFormatBlockHourInvoiceField = (e, id) => {
    _form(old => ({
      ...old,
      block_hour_invoice_item: [...old?.block_hour_invoice_item]
        ?.map(item => {
          if (item?.id === id) {
            item[e.target.name] = Number(e.target.value)?.toFixed(3) || 0;
          }
          return item;
        })
    }));
  };

  const handleSubmit = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(form));
    _isSubmitting(true);
    if (invoiceId) {
      BlockHourInvoiceEditRequest(token, JSON.stringify(data), invoiceId)
        .then(res => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return (res.json())
        })
        .then(data => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof (data) === 'string' && data.indexOf('Error') > -1)) {
            throw 'Request Failed';
          } else {
            getFleet();
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: 'invoice updated' });
            Navigate("/block-hour-invoices");
          }
        })
        .catch(err => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Request failed' });
        });
    }
  };


  // *********** Render Functions ***********

  const BLOCK_HOUR_DETAILS = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <label> Invoice Id </label>
          <input
            type='text'
            disabled={true}
            value={form?.id || ''}
            // value={form?.name}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Request Id </label>
          <input
            type='text'
            disabled={true}
            value={form?.request_id}
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
        <div className='w3-col l6 m6 s12'>
          <label> Aircraft </label>
          <input
            type='text'
            disabled={true}
            value={(form?.block_hour_invoice_item &&
              form?.block_hour_invoice_item?.find(item => item?.aircraft_tail_number)?.aircraft_tail_number
            ) || ''
            }
            // onChange={e => handleFleetFieldChange('name', e.target.value)}
            className='w3-input w3-border w3-round small-top-margin small-bottom-margin cursor-disable'
          />
        </div>
      </div>
    </React.Fragment>
  );

  const BLOCK_HOUR_INVOICE_ITEMS = () => (
    <React.Fragment>
      <ul className="list-details p-x-16">
        {form?.block_hour_invoice_item &&
          form?.block_hour_invoice_item?.map((item, index) => (
            <li key={index}>
              <div className="invoice-top-area flex gap-20">
                <div className="invoice-heading">
                  {`${item?.departure_airport_icao} / ${item?.departure_airport_iata} `}
                  →
                  {` ${item?.arrival_airport_icao} / ${item?.arrival_airport_iata}`}
                </div>
                <div>
                  <label className="w3-medium"> <strong>Departure:</strong> </label>
                  {dayjs(ignoreTimeZone(item?.departure_date_time))?.format('YYYY-MM-DD HH:mm')}
                </div>
                <div>
                  <label className="w3-medium"> <strong>Arrival:</strong> </label>
                  {dayjs(ignoreTimeZone(item?.arrival_date_time))?.format('YYYY-MM-DD HH:mm')}
                </div>
              </div>
              <div className='flex py-2 gap-10 cost-grids'>
                <div className='field'>
                  <label className='w3-medium'> Block Hours </label>
                  <input
                    maxLength={4}
                    name='block_hours'
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    value={item?.block_hours || 0}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> Crew Count </label>
                  <input
                    maxLength={4}
                    name='crew_count'
                    value={item?.crew_count || 0}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>                
                <div className='field'>
                  <label className='w3-medium'> Unit Cost </label>
                  <input
                    maxLength={4}
                    name='unit_cost'
                    value={item?.unit_cost || 0}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> Total Cost </label>
                  <input
                    maxLength={4}
                    name='total_cost'
                    value={item?.total_cost || 0}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                <div className='field'>
                  <label className='w3-medium'> VAT % </label>
                  <input
                    maxLength={4}
                    name='vat_percentage'
                    value={item?.vat_percentage || 0}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}
                    onBlur={e => handleFormatBlockHourInvoiceField(e, item?.id)}
                    className='w3-input w3-border w3-round'
                  />
                </div>
                {/* <div className='field'>
                  <label className='w3-medium'>VAT Amount </label>
                  <input
                    maxLength={4}      
                    name="vat_amount"     
                    value={item?.vat_amount || 0}
                    onChange={e => handleBlockHourInvoiceFieldChange(e, item?.id)}       
                    className='w3-input w3-border w3-round'                    
                  />
                </div> */}
              </div>
            </li>
          ))}
      </ul>
    </React.Fragment>
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className='w3-row-padding'>
        <div className='w3-col l6 m6 s12'>
          <button
            disabled={isSubmitting}
            onClick={handleSubmit}
            className='w3-btn bg-primary-blue w3-text-white small-top-margin'
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </button>
        </div>
      </div>
      <div className='h-2'></div>
    </React.Fragment>
  );

  const BLOCK_HOUR_FORM = () => (
    <div className='request-form-container w-full relative'>
      <span className='heading'> Block Hrs Invoice </span>
      <div className='border-primary-blue w3-round p-2'>
        {BLOCK_HOUR_DETAILS()}
        {BLOCK_HOUR_INVOICE_ITEMS()}
        {FORM_FOOTER()}
        {/* {BLOCK_HOUR_TOTAL_SECTION()} */}
      </div>
    </div>
  )

  const BLOCK_HOUR_TOTAL_SECTION = () => (
    <div className='request-form-container w-half relative py-2' style={{ float: 'right', width: '30%' }}>
      <span className='heading'> Block Hrs Invoice Total</span>
      <div className='border-primary-blue w3-round p-2'>
        <div className='flex py-2' style={{ justifyContent: 'flex-end' }}>
          <div>
            <label> Total Cost </label>
            <input
              maxLength={4}
              onChange={e => 0}
              className='w3-input w3-border w3-round'
              value={
                (() => {
                  let sum = 0;
                  form?.block_hour_invoice_item?.forEach((item) => {
                    sum += Number(item.total_cost) || 0;
                  });
                  return sum.toFixed(3);
                })()}
            // value={Number(item?.vat_amount).toFixed(3)}
            />
          </div>

        </div>
      </div>
    </div>
  )
  return (
    <div id='Invoice-Form'>
      {isLoading
        ? LOADER()
        : <>
          {BLOCK_HOUR_FORM()}
          {BLOCK_HOUR_TOTAL_SECTION()}
        </>
      }
    </div>
  )
}

export default BlockHourInvoicesForm;