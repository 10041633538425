// Packages
import React, { useEffect, useState } from "react";
import { useAtom } from "jotai";
import dayjs from "dayjs";
import { Store } from "react-notifications-component";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

// APIs
import { CrewMemberByFleetListRequest, FleetByAirbaseListRequest, AircraftByFleetListRequest, AirportDistanceRequest, AirbaseListRequest, ServiceListRequest, FsrEditRequest, FsrCreateRequest, FsrDetailsRequest, ContractServiceDetailsRequest, ApprovedFsrListRequest } from '../../../requests';

// Utils
import { auth, servicesCache, airbasesCache, loggedUserInfoCache } from '../../../atoms';
import { constants, generateUID, decodeString, authenticationErrorHandle, validateSubmissionData, calculateAirportFlightTime, minutesToHours, ignoreTimeZone, isLocalFlight, getCurrentGMTDateTime, verifyFileSizeLessThanOneMb } from '../../../utils';
import { AirportSelect, X, Loader, Edit, FileUpload } from '../../../components';
import { DuplicateCrewMemberModal, LegSetupModal, LegDeleteConfirmationModal, StepbackConfirmationModal, CreateCrewMemberModal, AllowEditLegConfirmModal } from "./components";
import RequestView from "../request-view";

function RequestForm() {
  const { requestId } = useParams();
  const Navigate = useNavigate();
  const INITIAL_FSR = {
    data: {
      portal_id: "",
      flight_number: "",
      airbase: "",
      aircraft: "",
      fleet: "",
      status: "Draft",
      urgent_request: false,
      purpose_of_flight: "",
      crewMembers: [],
      requestleg_requestLeg: []
    },
    validations: {
      flight_number: { isRequired: true },
      purpose_of_flight: { isRequired: true },
      airbase: { isRequired: true, isNumber: true },
      aircraft: { isRequired: true, isNumber: true },
      fleet: { isRequired: true, isNumber: true },
      requestleg_requestLeg: { isRequired: true, isArray: true }
    },
    leg_validations: {
      departure_airport_iata: { isRequired: true, min: 1 },
      destination_airport_iata: { isRequired: true, min: 1 },
    },
    errors: {}
  };

  const FORM_STEPS = {
    currentStep: 1,
  };

  const INPUT_FIELD_CLASS = `w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin ${requestId ? 'w3-disabled' : ''}`;

  const [fsr, _fsr] = useState({ ...INITIAL_FSR });
  const [fsrSteps, _fsrSteps] = useState({ ...FORM_STEPS });
  const [hasUserUploadedNewFile, _hasUserUploadedNewFile] = useState(false);
  const [approvedFsrList, _approvedFsrList] = useState([]);
  const [allowedEditableLegs, _allowedEditableLegs] = useState([]);
  const [selectedDeletingLegId, _selectedDeletingLegId] = useState(null);
  const [showConfirmRemoveLegModal, _showConfirmRemoveLegModal] = useState(false);
  const [showConfirmStepBackModal, _showConfirmStepBackModal] = useState(false);
  const [showAddCrewMemberModal, _showAddCrewMemberModal] = useState(false);
  const [showDuplicateCrewMemberModal, _showDuplicateCrewMemberModal] = useState(false);
  const [selectedDuplicateLegId, _selectedDuplicateLegId] = useState(null);
  const [showAllowEditLegModal, _showAllowEditLegModal] = useState(false);
  const [allowEditLegId, _allowEditLegId] = useState(null);
  const [isStep3Invalid, _isStep3Invalid] = useState({});
  const [isSubmitting, _isSubmitting] = useState(false);
  const [isLoading, _isLoading] = useState(requestId ? true : false);
  const [fleet, _fleet] = useState([]);
  const [aircrafts, _aircrafts] = useState([]);
  const [crewMembers, _crewMembers] = useState([]);
  const [contractServices, _contractServices] = useState([]);
  const [isContractServicesLoaded, _isContractServicesLoaded] = useState(false);
  const [selectedLeg, _selectedLeg] = useState(null);
  const [authState, _authState] = useAtom(auth);
  const [loggedUserInfo, _loggedUserInfo] = useAtom(loggedUserInfoCache);
  const [airbases, _airbases] = useAtom(airbasesCache);
  const [services, _services] = useAtom(servicesCache);
  const [invalidLegs, _invalidLegs] = useState([]);

  // Get services, crew members, airbases and menu items either from cache or from server
  useEffect(() => {
    if (authState) {
      // if (!crewMembers || !crewMembers.created || Date.now() - crewMembers.created >= 1200000) {
      //   getCrewMembers();
      // }
      if (!services || !services.created || Date.now() - services.created >= 1200000) {
        getServices();
      }
      if (!airbases || !airbases.created || Date.now() - airbases.created >= 1200000) {
        getAirbases();
      }
    }
  }, [authState, fsr?.data?.id]);

  // Set data in form for edit case
  useEffect(() => {
    // if (requestId && authState && airbases?.data && services?.data && crewMembers?.data) {
    if (requestId && authState && !fsr?.data?.id) {
      getApprovedFsrList(requestId);
      getFsrDetails();
    }
  }, [authState, requestId]);

  // Auto set fleet and contract in edit case
  useEffect(() => {
    if (!requestId && loggedUserInfo && loggedUserInfo?.data) {
      setFleetAndContractAndAirbase();
    }
  }, [authState, airbases, loggedUserInfo?.data])


  // Fetch list of fleet associated with the airbase when airbase is changed
  // Also change the departure of first leg according to airbase if it is edit case
  useEffect(() => {
    if (fsr?.data?.airbase) {
      getFleetByAirbase();
      if (fsr?.data?.requestleg_requestLeg?.length && !requestId) {
        const currentSelectedAirbase = airbases?.data?.find(Ab => Number(Ab.id) === Number(fsr?.data?.airbase));
        const departure_icao = currentSelectedAirbase && currentSelectedAirbase?.oca;
        const departure_iata = currentSelectedAirbase && currentSelectedAirbase?.iata;
        const departure_country = currentSelectedAirbase && currentSelectedAirbase?.country;
        const departure_city = currentSelectedAirbase && currentSelectedAirbase?.city;
        const airport_name = currentSelectedAirbase && currentSelectedAirbase?.name.replace(/[-/]+[/\w\s]*/, '');
        const Legs = [...fsr?.data?.requestleg_requestLeg];
        Legs[0].departure_airport_iata = departure_iata;
        Legs[0].departure = {
          airport_code: [departure_icao],
          country: [departure_country],
          city: [departure_city],
          airport_name: [airport_name]
        };
        _fsr(old => ({
          ...old,
          data: {
            ...old.data,
            requestleg_requestLeg: [...Legs]
          }
        }));
      }
    }
  }, [fsr?.data?.airbase]);

  // Fetch list of aircraft, contract-services & crew members associated with the fleet when fleet is changed
  useEffect(() => {
    if (fsr?.data?.fleet) {
      getAircraftsByFleet();
      getCrewMembersByFleet();
      getContractServiceDetails();
    }
  }, [fsr?.data?.fleet]);

  // Set the flight no as aircraft's tail number on aircraft select if flight no. is not manually entered
  useEffect(() => {
    if (fsr?.data?.aircraft && !fsr?.data?.flight_number) {
      const selectedAircraft = aircrafts && aircrafts?.data?.find(A => A.id === fsr?.data?.aircraft);
      const flight_number_from_aircraft = selectedAircraft?.tailnumber.replace(/[^a-zA-Z0-9]/g, '') || '';
      _fsr(old => ({
        ...old,
        data: {
          ...old.data,
          flight_number: flight_number_from_aircraft
        }
      }));

    }
  }, [fsr?.data?.aircraft]);

  const getAirbases = () => {
    const token = decodeString(authState);
    AirbaseListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in cache with current time
        _airbases({
          data: [...nonPaginatedData],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch airbases" });
    });
  };

  const getServices = () => {
    const token = decodeString(authState);
    ServiceListRequest(token).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data && data.results) {
        // Keep server data in cache with current time
        _services({
          data: [...data.results],
          created: Date.now(),
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch services" });
    });
  };

  const getFleetByAirbase = () => {
    const token = decodeString(authState);
    FleetByAirbaseListRequest(token, fsr?.data?.airbase).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _fleet({
          data: [...nonPaginatedData],
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch fleet" });
    });
  };

  const getAircraftsByFleet = () => {
    const token = decodeString(authState);
    AircraftByFleetListRequest(token, fsr?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _aircrafts({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch aircrafts" });
    });
  };

  const getCrewMembersByFleet = () => {
    const token = decodeString(authState);
    CrewMemberByFleetListRequest(token, fsr?.data?.fleet).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((nonPaginatedData) => {
      if (constants.LOGOUTERRORTYPES.includes(nonPaginatedData?.errorCodes)) return;
      if (nonPaginatedData) {
        // Keep server data in local state
        _crewMembers({
          data: [...nonPaginatedData]
        });
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch crew members" });
    });
  };

  const getContractServiceDetails = () => {
    const token = decodeString(authState);
    _isContractServicesLoaded(false);
    ContractServiceDetailsRequest(token, fsr?.data?.fleet).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        data = data?.map(d => (
          {
            ...d,
            price: Number(d?.price).toFixed(2)
          })
        )
          || [];
        _isContractServicesLoaded(true);
        _contractServices(data);
      } else {
        throw 'Request Failed';
      }
    }
    ).catch(err => {
      console.error(err);
      _isContractServicesLoaded(true);
      Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch contract service details' });
    }
    )
  };

  const getFsrDetails = () => {
    const token = decodeString(authState);
    _isLoading(true);
    FsrDetailsRequest(token, requestId).then((res) => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return res.json();
    }).then((data) => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _isLoading(false);
        // delete data.createdat;
        // delete data.createdby;
        // delete data.updatedat;
        // delete data.updatedby;
        data.crewMembers = data.requestleg_requestLeg?.[0]?.requestlegcrewmember_crewMembers?.map(c => c.crewmember);
        data.requestleg_requestLeg.forEach((L) => {
          L.destination = {};
          L.departure = {};
          L.arrival_date = dayjs(ignoreTimeZone(L.arrival_time)).format('YYYY-MM-DD');
          L.departure_date = dayjs(ignoreTimeZone(L.departure_time)).format('YYYY-MM-DD');
          L.arrival_time = dayjs(ignoreTimeZone(L.arrival_time)).format('HH:mm');
          L.departure_time = dayjs(ignoreTimeZone(L.departure_time)).format('HH:mm');
          L.departure.airport_code = [L.departure_airport_icao];
          L.departure.country = [L.departure_airport_country];
          L.departure.city = [L.departure_airport_city];
          L.departure.airport_name = [L.departure_airport_name];
          L.destination.airport_code = [L.destination_airport_icao];
          L.destination.country = [L.destination_airport_country];
          L.destination.city = [L.destination_airport_city];
          L.destination.airport_name = [L.destination_airport_name];
          L.arrival_airport_type = L?.arrival_airport_type || 'Civilian';
          L.departure_airport_type = L?.departure_airport_type || 'Civilian';
          // Don't change position of these constants, their values are set in above code
          const arrival_date_time = dayjs(`${L.arrival_date} ${L.arrival_time}`);
          const departure_date_time = dayjs(`${L.departure_date} ${L.departure_time}`)
          const duration = minutesToHours(arrival_date_time.diff(departure_date_time, 'minute'));
          const crewMembersObj = [...L.requestlegcrewmember_crewMembers];
          const serviceObj = [...L.requestlegservice_services];
          const serviceObj_to = [...L.requestlegservice_services_to];
          L.flightTime = { hrs: duration?.hrs, mins: duration?.mins };
          L.requestleghotel_requestLegHotel = L?.requestleghotel_requestLegHotel?.map(H => ({ ...H, }));

        });
        _allowedEditableLegs([]);
        // Filter out cancelled legs
        // data.requestleg_requestLeg = [...data.requestleg_requestLeg?.filter(L => L?.status.toLowerCase() !== ('canceled'))]
        _fsr(old => ({
          ...old,
          data
        }));
      } else {
        throw "Request Failed";
      }
    }).catch((err) => {
      _isLoading(false);
      console.error(err);
      Store.addNotification({ ...constants.ERRORTOAST, message: "Failed to fetch request details" });
    });
  };

  const getApprovedFsrList = (requestId) => {
    const token = decodeString(authState);
    ApprovedFsrListRequest(token, requestId).then(res => {
      if (res && res?.status === 401) {
        authenticationErrorHandle(() => _authState('0'));
        return (
          { errorCodes: constants.SESSIONTIMEOUT }
        );
      } else return (res.json())
    }).then(data => {
      if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
      if (data) {
        _approvedFsrList(old => [...old, ...data]);
      } else {
        throw 'Request Failed';
      }
    }
    )
      .catch(
        err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: 'Failed to fetch approved request history' });
        }
      );
  };

  const setFleetAndContractAndAirbase = () => {
    // Set pre-selected fleet and airbase according to loggedIn user's info
    if (loggedUserInfo?.data?.fleet && loggedUserInfo?.data?.fleet?.id) {
      const contract = airbases && airbases?.data?.find(A => A.id === loggedUserInfo?.data?.fleet?.airbase)?.contract;
      _fsr(old => ({
        ...old,
        data: {
          ...old?.data,
          fleet: loggedUserInfo?.data?.fleet?.id || '',
          airbase: loggedUserInfo?.data?.fleet?.airbase || '',
          sendby: loggedUserInfo?.data?.username || '',
          contract: contract || ''
        }
      }));
    }
  };

  const setRequestData = (type, rawData = null) => {
    const data = JSON.parse(JSON.stringify(rawData || fsr?.data));
    delete data.updatedat;
    delete data.createdat;
    if (type === 'edit') {
      data?.requestleg_requestLeg.forEach(L => {
        // set crew members
        if (L?.requestlegcrewmember_crewMembers && L?.requestlegcrewmember_crewMembers?.length !== 0) {
          L.requestlegcrewmember_crewMembers = L?.requestlegcrewmember_crewMembers?.map(c => (
            {
              ...c,
              ...c?.value,
              ...(parseInt(c?.id) && { id: c?.id }),
              requestLeg: c?.requestLeg || L.id,
              value: null
            }));
        } else {
          // if leg doesn't have any crew then copy the main request body's crew into that leg
          // L.requestlegcrewmember_crewMembers = fsr?.data?.crewMembers.map(c => ({
          //   requestLeg: c?.requestLeg || L.id,
          //   crewmember: c
          // }));
        }

        // set car rental
        L.reqlegcarrental_requestLegCarRental = L?.reqlegcarrental_requestLegCarRental?.map(c => (
          {
            ...(parseInt(c?.id) && { id: c?.id }),
            crewmembers: [],
            car_rental_type: c?.car_rental_type,
            drop_off_date: c?.drop_off_date,
            pick_up_date: c?.pick_up_date,
            rental_days: c?.rental_days,
            requestLeg: c?.requestLeg || L.id,
          }
        ));
        // set number of cars in carRental array according to formula 4 person per car.
        // const NumberOfCars = Math.ceil(L?.requestLegCarRental?.[0]?.crewmembers?.length / 4) || 0;
        const NumberOfCars = Math.ceil(L?.requestlegcrewmember_crewMembers?.length / 4) || 0;
        // L.requestLegCarRental = new Array(NumberOfCars).fill(L?.requestLegCarRental?.[0]);

        // services for FROM Destination
        if (L?.requestlegservice_services_from && L?.requestlegservice_services_from?.length !== 0) {
          L.requestlegservice_services_from = L?.requestlegservice_services_from?.map(s => (
            {
              ...s,
              ...s?.value,
              ...(parseInt(s?.id) && { id: s?.id }),
              service: s?.service,
              company: s?.company,
              requestLeg: s?.requestLeg || L.id,
              value: null
            }
          ));
        }

        // service for TO Destination
        if (L?.requestlegservice_services_to && L?.requestlegservice_services_to?.length !== 0) {
          L.requestlegservice_services_to = L?.requestlegservice_services_to?.map(s => (
            {
              ...s,
              ...s?.value,
              ...(parseInt(s?.id) && { id: s?.id }),
              service: s?.service,
              company: s?.company,
              requestLeg: s?.requestLeg || L.id,
              value: null
            }
          ));
        }

        // set services
        L.requestlegservice_services = [
          ...L.requestlegservice_services_from?.map(S => ({ ...S, is_destination: false })),
          ...L.requestlegservice_services_to?.map(S => ({ ...S, is_destination: true }))
        ];

        // set hotels
        L.requestleghotel_requestLegHotel = L?.requestleghotel_requestLegHotel?.map(h => (
          {
            ...(parseInt(h.id) && { id: h?.id }),
            hotelName: "hotel",
            hotelType: "hotel",
            checkInDateTime: h?.checkInDateTime,
            checkOutDateTime: h?.checkOutDateTime,
            description: h?.description,
            requestLeg: h?.requestLeg || L.id,
            requestleghotelpax_requestLegHotelPAX: h?.requestleghotelpax_requestLegHotelPAX?.map(p => (
              {
                ...p,
                ...(parseInt(p?.id) && { id: p?.id }),
                ...(parseInt(p?.requestLegHotel) && { requestLegHotel: p?.requestLegHotel })
              }
            ))
          }
        ));

        // set transport
        L.reqlegtransport_requestLegTransport = L?.reqlegtransport_requestLegTransport?.map(t => (
          {
            ...t,
            ...(parseInt(t?.id) && { id: t?.id }),
            requestLeg: t?.requestLeg || L.id,
            transport_to: t?.transport_type?.split('to')?.pop(),
            transport_from: t?.transport_type?.split('to')?.shift()
          }
        ));

        // catering for FROM Destination 
        L.requestlegcatering_requestLegCatering_from = L?.requestlegcatering_requestLegCatering_from?.map(c => (
          {
            ...(parseInt(c?.id) && { id: c?.id }),
            paxcat: c?.paxcat,
            crewcat: c?.crewcat,
            description: c?.description,
            requestLeg: c?.requestLeg || L.id,
            requestLegCateringItem: c?.requestLegCateringItem || [],
            type_of_catering: c?.type_of_catering
          }
        ));

        // catering for TO Destination
        L.requestlegcatering_requestLegCatering_to = L?.requestlegcatering_requestLegCatering_to?.map(c => (
          {
            ...(parseInt(c?.id) && { id: c?.id }),
            paxcat: c?.paxcat,
            crewcat: c?.crewcat,
            description: c?.description,
            requestLeg: c?.requestLeg || L.id,
            requestLegCateringItem: c?.requestLegCateringItem || [],
            type_of_catering: c?.type_of_catering
          }
        ));

        // set catering
        L.requestlegcatering_requestLegCatering = [
          ...L.requestlegcatering_requestLegCatering_from?.map(C => ({ ...C, is_destination: false })),
          ...L.requestlegcatering_requestLegCatering_to?.map(C => ({ ...C, is_destination: true }))
        ];

        L.departure_date_time = `${L.departure_date}T${L.departure_time}:00`;
        L.departure_time = L.departure_date_time;
        L.arrival_date_time = `${L.arrival_date}T${L.arrival_time}:00`;
        L.arrival_time = L.arrival_date_time;
        L.departure_airport_icao = L?.departure?.airport_code?.[0];
        L.departure_airport_country = L.departure?.country[0];
        L.departure_airport_city = L.departure?.city[0] || 'Undefined';
        L.departure_airport_name = L.departure?.airport_name?.[0];
        L.destination_airport_icao = L?.destination?.airport_code?.[0];
        L.destination_airport_country = L.destination?.country[0];
        L.destination_airport_city = L.destination?.city[0] || 'Undefined';
        L.destination_airport_name = L.destination?.airport_name?.[0];
        L.aircraft = fsr?.data?.aircraft;
        L.errorFieldId = L.id; // set Id in un-used key to check errors in legs
        delete L.destination;
        delete L.departure;
        delete L.requestlegservice_services_to;
        delete L.requestlegservice_services_from;
        delete L.requestlegcatering_requestLegCatering_to;
        delete L.requestlegcatering_requestLegCatering_from;
        if (!parseInt(L.id)) {
          delete L.id; // remove Id if the leg is newly added in request
        }
      });
    } else {
      data?.requestleg_requestLeg?.forEach(L => {
        L.departure_date_time = `${L.departure_date}T${L.departure_time}:00`;
        L.departure_time = L.departure_date_time;
        L.arrival_date_time = `${L.arrival_date}T${L.arrival_time}:00`;
        L.arrival_time = L.arrival_date_time;
        L.aircraft = fsr?.data?.aircraft;
        L.departure_airport_icao = L?.departure?.airport_code?.[0];
        L.departure_airport_country = L.departure?.country[0];
        L.departure_airport_city = L.departure?.city[0] || 'Undefined';
        L.departure_airport_name = L.departure?.airport_name?.[0];
        L.destination_airport_icao = L?.destination?.airport_code?.[0];
        L.destination_airport_country = L.destination?.country[0];
        L.destination_airport_city = L.destination?.city[0] || 'Undefined';
        L.destination_airport_name = L.destination?.airport_name?.[0];
        // set services
        L.requestlegservice_services = L?.requestlegservice_services?.length
          ? L?.requestlegservice_services
          : contractServices?.filter(cs => Number(cs?.price) > 0 && Boolean(cs?.is_default))
            ?.map(cs => ({ service: cs?.service, company: loggedUserInfo?.data?.company, quantity: 1 }));
        // services to and from 
        L.requestlegservice_services = [
          ...L.requestlegservice_services?.map(S => ({ ...S, is_destination: false })),
          ...L.requestlegservice_services?.map(S => ({ ...S, is_destination: true }))
        ];

        // set crew members
        L.requestlegcrewmember_crewMembers = L?.requestlegcrewmember_crewMembers?.length
          ? L?.requestlegcrewmember_crewMembers
          : fsr?.data?.crewMembers?.map(c => ({ crewmember: c }));
        delete L.destination;
        delete L.departure;
        delete L.id;
      });
      data.portal_id = dayjs().format('ddDDHmmsSSS'); // set hardcodded random generated portal id
    }
    return (data);
  };

  const adjustDateTimeForNextLegs = (Legs = []) => {
    Legs?.forEach((leg, index) => {
      if (index > 0) {
        const currentLegDeparture = dayjs(leg?.departure_date + ' ' + leg?.departure_time);
        const previousLegArrival = dayjs(Legs?.[index - 1]?.arrival_date + ' ' + Legs?.[index - 1]?.arrival_time);
        if (previousLegArrival >= currentLegDeparture) {
          const newDate = dayjs(previousLegArrival)?.add(1, 'day');
          leg.departure_date = newDate?.format('YYYY-MM-DD');
          leg.departure_time = newDate?.format('HH:mm');
          let flightDuration = dayjs(`${leg?.departure_date} ${leg?.departure_time}`);
          flightDuration = flightDuration.add(leg?.flightTime?.hrs || 0, 'hour');
          flightDuration = flightDuration.add(leg?.flightTime?.mins || 0, 'minute');
          leg.arrival_date = flightDuration?.format('YYYY-MM-DD');
          leg.arrival_time = flightDuration?.format('HH:mm');
        }
      }
    });
    return [...Legs];
  }

  // *********** Handlers ***********

  const handleFsrFieldChange = (name, value) => {
    _fsr(old => ({
      ...old,
      data: {
        ...old.data,
        [name]: value
      }
    }));
  };

  const handleAddNewLeg = () => {
    // If fleet is not selected, don't let user add new legs
    if (!fsr?.data?.fleet) return;
    const Leg = {
      id: generateUID("leg_"),
      departure: null,
      destination: null,
      departure_airport_iata: "",
      destination_airport_iata: "",
      departure_date: "",
      arrival_date: "",
      departure_time: "",
      arrival_time: "",
      arrival_airport_type: 'Civilian',
      departure_airport_type: 'Civilian',
      flightTime: { hrs: 1, mins: 0 },
      pax_count: 0,
      leg_type: '',
      fuel_uplift: '',
      permit_description: '',
      requestlegservice_services: [],
      requestlegservice_services_to: [],
      requestlegservice_services_from: [],
      requestlegcrewmember_crewMembers: [],
      requestleghotel_requestLegHotel: [],
      requestlegcatering_requestLegCatering: [],
      requestlegcatering_requestLegCatering_to: [],
      requestlegcatering_requestLegCatering_from: [],
      reqlegtransport_requestLegTransport: [],
      reqlegcarrental_requestLegCarRental: []
    };
    // Handling for newly added leg to use previous leg fields data
    if (fsr?.data?.requestleg_requestLeg?.length) {
      const lastLeg = fsr?.data?.requestleg_requestLeg[fsr?.data?.requestleg_requestLeg?.length - 1];
      const departure_date = dayjs(`${lastLeg?.arrival_date} ${lastLeg?.arrival_time || '00:00'}`)?.add(1, 'hour').format("YYYY-MM-DD");
      const departure_time = dayjs(`${lastLeg?.arrival_date} ${lastLeg?.arrival_time || '00:00'}`)?.add(1, 'hour').format("HH:mm");
      const arrival_date = dayjs(departure_date).add(2, 'hour').format('YYYY-MM-DD');
      const arrival_time = dayjs(departure_date).add(2, 'hour').format('HH:mm');
      Leg.departure = lastLeg.destination || null;
      Leg.departure_airport_iata = lastLeg.destination_airport_iata || "";
      // Leg.departure_date = departure_date;
      // Leg.departure_time = departure_time;
      // Leg.arrival_date = arrival_date;
      // Leg.arrival_time = arrival_time;
    } else {
      // If its first leg then take departure airport from airbase
      const currentSelectedAirbase = airbases?.data?.find(Ab => Number(Ab.id) === Number(fsr?.data?.airbase));
      const departure_icao = currentSelectedAirbase && currentSelectedAirbase?.oca;
      const departure_iata = currentSelectedAirbase && currentSelectedAirbase?.iata;
      const departure_country = currentSelectedAirbase && currentSelectedAirbase?.country;
      const departure_city = currentSelectedAirbase && currentSelectedAirbase?.city;
      const airport_name = currentSelectedAirbase && currentSelectedAirbase?.name?.replace(/[-/]+[/\w\s]*/, '');
      Leg.departure_airport_iata = departure_iata;
      Leg.departure = {
        airport_code: [departure_icao],
        country: [departure_country],
        city: [departure_city],
        airport_name: [airport_name]
      };
    }

    _fsr((old) => ({
      ...old,
      data: {
        ...old.data,
        requestleg_requestLeg: [...old?.data?.requestleg_requestLeg, Leg],
      }
    }));
  };

  const handleChangeLeg = (legId, name, value, event) => {
    let Legs = [...fsr?.data?.requestleg_requestLeg];
    Legs.forEach((L) => {
      if (L.id === legId) {
        L[name] = value;
        if (name === 'arrival_date') L.arrival_date = value;
        else if (name === 'arrival_time') L.arrival_time = value;
      }
    });

    _fsr((old) => ({
      ...old,
      data: {
        ...old?.data,
        requestleg_requestLeg: [...Legs]
      }
    }));
  };

  const handleLegValidations = (legId, name, value, event) => {
    let Legs = [...fsr?.data?.requestleg_requestLeg];
    let newInvalidLegs = [...invalidLegs];
    Legs.forEach((L) => {
      if (L.id === legId) {
        L[name] = value;
        newInvalidLegs = newInvalidLegs.filter(invalidLeg => invalidLeg?.id !== legId);
        const datetime2 = dayjs(L?.arrival_date + ' ' + L?.arrival_time);
        const datetime1 = dayjs(L?.departure_date + ' ' + L?.departure_time);
        const datetimeNow = dayjs(getCurrentGMTDateTime());
        if (datetimeNow > datetime1) {
          newInvalidLegs = [...newInvalidLegs, { id: legId, type: 'departure_date', message: "Departure datetime can't be less than current zulu datetime" }];
          Store.addNotification({ ...constants.ERRORTOAST, message: "Departure datetime can't be less than current zulu datetime" });
        }
        else if (!(datetime2 > datetime1)) {
          newInvalidLegs = [...newInvalidLegs, { id: legId, type: 'arrival_date', message: "Arrival datetime can't be less than departure datetime" }];
          Store.addNotification({ ...constants.ERRORTOAST, message: "Arrival datetime can't be less than departure datetime" });
        }
        else if (Legs?.findIndex(L => L.id === legId) > 0) {
          const previousLeg = Legs[Legs?.findIndex(L => L.id === legId) - 1 || 0];
          const previousLegDepartureDateTime = dayjs(previousLeg?.departure_date + ' ' + previousLeg?.departure_time);
          const previousLegArrivalDateTime = dayjs(previousLeg?.arrival_date + ' ' + previousLeg?.arrival_time);
          if (datetime1 < previousLegArrivalDateTime) {
            newInvalidLegs = [...newInvalidLegs, { id: legId, type: 'departure_date', message: "Departure datetime can't be less than previous leg's arrival datetime" }];
            Store.addNotification({ ...constants.ERRORTOAST, message: "Departure datetime can't be less than previous leg's arrival datetime" });
          }
        }
        else if (Legs?.findIndex(L => L.id === legId) !== Legs?.length - 1) {
          // const previousLeg = Legs[Legs?.findIndex(L => L.id === legId) - 1 || 0];
          // const previousLegDepartureDateTime = dayjs(previousLeg?.departure_date + ' ' + previousLeg?.departure_time);
          // const previousLegArrivalDateTime = dayjs(previousLeg?.arrival_date + ' ' + previousLeg?.arrival_time);
          // if (datetime1 < previousLegArrivalDateTime) {
          //   newInvalidLegs = [...newInvalidLegs, { id: legId, type: 'departure_date', message: "Departure datetime can't be less than previous leg's arrival datetime" }];
          //   Store.addNotification({ ...constants.ERRORTOAST, message: "Departure datetime can't be less than previous leg's arrival datetime" });
          // }
        }
      }
    });
    _invalidLegs(newInvalidLegs);
    _fsr((old) => ({
      ...old,
      data: {
        ...old?.data,
        requestleg_requestLeg: [...Legs]
      }
    }));
  };

  const handleAllLegValidations = () => {
    let Legs = [...fsr?.data?.requestleg_requestLeg];
    let newInvalidLegs = [];
    Legs.forEach((L, index) => {
      newInvalidLegs = newInvalidLegs.filter(invalidLeg => invalidLeg?.id !== L?.id);
      const datetime2 = dayjs(L?.arrival_date + ' ' + L?.arrival_time);
      const datetime1 = dayjs(L?.departure_date + ' ' + L?.departure_time);
      const datetimeNow = dayjs(getCurrentGMTDateTime());
      if (requestId
        ? (allowedEditableLegs?.includes(L?.id) && datetimeNow > datetime1)
        : datetimeNow > datetime1
      ) {
        newInvalidLegs = [...newInvalidLegs, { id: L?.id, type: 'departure_date', message: "Departure datetime can't be less than current zulu datetime" }];
        Store.addNotification({ ...constants.ERRORTOAST, message: "Departure datetime can't be less than current zulu datetime" });
      }
      else if (!(datetime2 > datetime1)) {
        newInvalidLegs = [...newInvalidLegs, { id: L?.id, type: 'arrival_date', message: "Arrival datetime can't be less than departure datetime" }];
        Store.addNotification({ ...constants.ERRORTOAST, message: "Arrival datetime can't be less than departure datetime" });
      }
      else if (index > 0) {
        const previousLeg = Legs[index - 1 || 0];
        const previousLegDepartureDateTime = dayjs(previousLeg?.departure_date + ' ' + previousLeg?.departure_time);
        const previousLegArrivalDateTime = dayjs(previousLeg?.arrival_date + ' ' + previousLeg?.arrival_time);
        if (datetime1 < previousLegArrivalDateTime) {
          newInvalidLegs = [...newInvalidLegs, { id: L?.id, type: 'departure_date', message: "Departure datetime can't be less than previous leg's arrival datetime" }];
          Store.addNotification({ ...constants.ERRORTOAST, message: "Departure datetime can't be less than previous leg's arrival datetime" });
        }
      }
    });
    _invalidLegs(newInvalidLegs);
    _fsr((old) => ({
      ...old,
      data: {
        ...old?.data,
        requestleg_requestLeg: [...Legs]
      }
    }));
    if (newInvalidLegs.length) return false;
    else return true;
  };

  const handleRemoveLegPrecheck = (legId) => {
    if ((!fsr?.data?.id && !requestId) || isNaN(Number(legId))) {
      handleRemoveLeg(legId);
    } else {
      _selectedDeletingLegId(legId);
      handleOpenConfirmRemoveLegModal();
    }
  };

  const handleRemoveLeg = (legId) => {
    if (selectedDeletingLegId) _selectedDeletingLegId(null);
    if (showConfirmRemoveLegModal) _showConfirmRemoveLegModal(false);

    // If any center leg is removed then it's departure airport 
    // will be transferred to the next leg which is at next index to the deleted leg
    let Legs = [...fsr?.data?.requestleg_requestLeg];
    const legIndex = Legs?.findIndex(L => L?.id === legId);
    const deletingLeg = Legs?.find(L => L?.id === legId);
    if (legIndex !== Legs?.length - 1 && deletingLeg?.departure_airport_iata && deletingLeg?.destination_airport_iata) {
      const nextLeg = Legs?.[legIndex + 1];
      nextLeg.departure_airport_iata = deletingLeg.departure_airport_iata;
      nextLeg.departure = deletingLeg.departure;
      const distancePayload = `[{"t": "${nextLeg?.departure_airport_iata}"}, {"t": "${nextLeg?.destination_airport_iata}"}]`
      AirportDistanceRequest(null, distancePayload)
        .then(res => (res.json()))
        .then(data => {
          if (!data) throw 'Failed to calculate distance';
          const flightTime = calculateAirportFlightTime(data);
          let flightDuration = dayjs(`${nextLeg?.departure_date} ${nextLeg.departure_time}`);
          flightDuration = flightDuration?.add(flightTime?.hrs, 'hour');
          flightDuration = flightDuration?.add(flightTime?.mins, 'minute');
          nextLeg.flightTime = flightTime;
          nextLeg.arrival_date = flightDuration?.format('YYYY-MM-DD');
          nextLeg.arrival_time = flightDuration?.format('HH:mm');
          nextLeg.destination_airport_iata = nextLeg?.destination_airport_iata || deletingLeg?.destination_airport_iata;
          nextLeg.destination = nextLeg?.destination || deletingLeg.destination;
          nextLeg.is_local = isLocalFlight(nextLeg?.departure?.airport_code?.[0], nextLeg?.destination?.airport_code?.[0]);
          Legs = Legs?.filter(L => L?.id !== legId);
          Legs.forEach(L => {
            if (L?.id === nextLeg?.id)
              L = nextLeg;
          });
          _fsr((old) => ({
            ...old,
            data: {
              ...old.data,
              requestleg_requestLeg: Legs,
            }
          }));
        })
        .catch(err => {
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
        });
    } else {
      Legs = Legs.filter((L) => L.id !== legId);
      _fsr((old) => ({
        ...old,
        data: {
          ...old.data,
          requestleg_requestLeg: Legs,
        }
      }));
    }
  };

  // Cache data for legs
  const handleSaveUpdatedLeg = (leg) => {
    const data = { ...fsr?.data };
    const Legs = [...fsr?.data?.requestleg_requestLeg]?.map(L => {
      if (L.id === leg.id) return leg
      else return L;
    });
    data.requestleg_requestLeg = [...Legs];
    _selectedLeg(null);
    _fsr(old => ({
      ...old,
      data
    })
    );
  };

  const handleDepartureChange = (legId, iata, airport) => {
    let legs = [...fsr?.data?.requestleg_requestLeg];
    legs.forEach((L) => {
      if (L.id === legId) {
        L.departure_airport_iata = iata[0];
        L.departure = airport;
        L.is_local = isLocalFlight(L?.departure?.airport_code?.[0], L?.destination?.airport_code?.[0]);
      }
    });
    _fsr((old) => ({
      ...old,
      requestleg_requestLeg: legs,
    }));
  };

  const handleDestinationChange = (legId, iata, airport) => {
    let legs = [...fsr?.data?.requestleg_requestLeg];
    legs.forEach((L, legIndex) => {
      if (L.id === legId) {
        L.destination_airport_iata = iata[0];
        L.destination = airport;
        L.is_local = isLocalFlight(L?.departure?.airport_code?.[0], L?.destination?.airport_code?.[0]);
        if (L?.departure_airport_iata) {
          // change the departure of next leg to be the same as arrival of previous leg
          const nextLeg = legs?.[legIndex + 1];
          if (nextLeg && nextLeg?.departure_airport_iata !== L.destination_airport_iata) {
            legs[legIndex + 1].departure_airport_iata = iata[0];
            legs[legIndex + 1].departure = airport;
            legs[legIndex + 1].is_local = isLocalFlight(legs?.[legIndex + 1]?.departure?.airport_code?.[0], legs?.[legIndex + 1]?.destination?.airport_code?.[0]);
          }
          _fsr((old) => ({
            ...old,
            data: {
              ...old?.data,
              requestleg_requestLeg: legs,
            }
          }));
        }
      }
    });
  };

  const handleAirbaseChange = (name, value) => {
    // Reset aircraft selection if airbase is changed & set contract value according to airbase contract
    let contract = "";
    if (airbases && airbases.data) {
      const selectedAirbase = airbases?.data.find((A) => A.id == value);
      contract = selectedAirbase?.contract;
    }
    _fsr((old) => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        contract,
        fleet: "",
        aircraft: "",
      }
    }));
  };

  const handleCrewMembersChange = (crew) => {
    _fsr(old => ({
      ...old,
      data: {
        ...old?.data,
        crewMembers: crew?.map(c => (c?.value?.id))
      }
    }));
  };

  const handleFleetChange = (name, value) => {
    _fsr((old) => ({
      ...old,
      data: {
        ...old?.data,
        [name]: value,
        aircraft: ""
      }
    }));
  };

  const handleValidateStepOne = (data) => {
    const legsValid = handleAllLegValidations();
    let allValid = true, errors = {};
    ({ allValid, errors } = validateSubmissionData(data, fsr?.validations));
    errors.legs = {};
    // check the validations for each leg using the fsr.leg_validations object
    data?.requestleg_requestLeg.forEach(L => {
      const { allValid: legsValid, errors: legErrors } = validateSubmissionData(L, fsr?.leg_validations);
      if (!legsValid) {
        errors.legs[L?.errorFieldId] = legErrors;
        allValid = legsValid;
      }
    });
    _fsr(old => ({
      ...old,
      errors
    }))
    if (!allValid || !legsValid) {
      return false;
    } return true;
  };

  const handleValidateStepThree = (data) => {
    let isValid = true;
    if (!data?.sendby || data?.sendby?.trim() == '') {
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have error" });
      _isStep3Invalid(old => ({ ...old, sendby: true }));
      isValid = false;
    } else {
      _isStep3Invalid(old => ({ ...old, sendby: false }));
    } if (!data?.contact_person_details || data?.contact_person_details?.trim() == '') {
      Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have error" });
      _isStep3Invalid(old => ({ ...old, contact_person_details: true }));
      isValid = false;
    }
    else {
      _isStep3Invalid(old => ({ ...old, contact_person_details: false }));
    }
    return isValid;
  };

  const handleNextStep = () => {
    const token = decodeString(authState);
    const data = JSON.parse(JSON.stringify(fsr?.data));
    data?.requestleg_requestLeg?.forEach(Leg => {
      Leg.errorFieldId = Leg.id; // set Id in un-used key to check errors in legs
    })
    if (fsrSteps?.currentStep === 1) {
      if (!handleValidateStepOne(data)) {
        Store.addNotification({ ...constants.ERRORTOAST, message: "Required fields have errors" });
        return;
      } else {
        if (!requestId && !fsr?.data?.id) {
          const submitData = setRequestData();
          const form_data = new FormData();
          const JSONStringData = JSON.stringify(submitData);
          FsrCreateRequest(token, JSONStringData)
            .then((res) => {
              if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                  { errorCodes: constants.SESSIONTIMEOUT }
                );
              } else return res.json();
            })
            .then((data) => {
              if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
              if (typeof data === "string" && data.indexOf("Error") > -1) {
                throw "Request Failed";
              } else if (data?.id) {
                Navigate(`/requests/edit/${data?.id}`);
                _isSubmitting(false);
                _fsrSteps(old => ({ currentStep: old?.currentStep + 1 }));
                Store.addNotification({ ...constants.SUCCESSTOAST, message: "Request submitted" });
              }
            })
            .catch((err) => {
              _isSubmitting(false);
              console.error(err);
              Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
            });
        } else {
          const submitData = setRequestData('edit');
          submitData.status = 'Draft';
          const form_data = new FormData();
          const JSONStringData = JSON.stringify(submitData);
          FsrEditRequest(token, JSONStringData, requestId)
            .then((res) => {
              if (res && res?.status === 401) {
                authenticationErrorHandle(() => _authState('0'));
                return (
                  { errorCodes: constants.SESSIONTIMEOUT }
                );
              } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
                throw 'Request Failed';
              } else return res.json();
            })
            .then((data) => {
              if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
              if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
                throw "Request Failed";
              } else {
                // getFsrList();  Caching for FSR requests not applied
                _isSubmitting(false);
                getFsrDetails();
                Store.addNotification({ ...constants.SUCCESSTOAST, message: "Request updated" });
              }
            })
            .catch((err) => {
              _isSubmitting(false);
              console.error(err);
              Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
            });
          _fsrSteps(old => ({ currentStep: old?.currentStep + 1 }));
        }
      }
    } else if (fsrSteps?.currentStep === 2) {
      _fsrSteps(old => ({ currentStep: old?.currentStep + 1 }));
    } else if (fsrSteps?.currentStep === 3) {
      const submitData = setRequestData('edit');
      if (!handleValidateStepThree(submitData)) return;
      _isSubmitting(true);
      submitData.status = 'Confirmed';
      const form_data = new FormData();
      const JSONStringData = JSON.stringify(submitData);
      FsrEditRequest(token, JSONStringData, requestId)
        .then((res) => {
          if (res && res?.status === 401) {
            authenticationErrorHandle(() => _authState('0'));
            return (
              { errorCodes: constants.SESSIONTIMEOUT }
            );
          } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
            throw 'Request Failed';
          } else return res.json();
        })
        .then((data) => {
          if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
          if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
            throw "Request Failed";
          } else {
            _isSubmitting(false);
            Store.addNotification({ ...constants.SUCCESSTOAST, message: "Request updated" });
            Navigate("/requests");
          }
        })
        .catch((err) => {
          _isSubmitting(false);
          console.error(err);
          Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
        })
    }
  };

  const handleBackStep = () => {
    _fsrSteps(old => ({ currentStep: old?.currentStep - 1 }));
  };

  const handleSaveDraft = () => {
    const token = decodeString(authState);
    const submitData = setRequestData('edit');
    submitData.status = 'Draft';
    // console.log(submitData);
    // return;
    _isSubmitting(true);
    const form_data = new FormData();
    const JSONStringData = JSON.stringify(submitData);
    FsrEditRequest(token, JSONStringData, requestId)
      .then((res) => {
        if (res && res?.status === 401) {
          authenticationErrorHandle(() => _authState('0'));
          return (
            { errorCodes: constants.SESSIONTIMEOUT }
          );
        } else if (constants.RESPONSECODES.indexOf(res?.status) < 0) {
          throw 'Request Failed';
        } else return res.json();
      })
      .then((data) => {
        if (constants.LOGOUTERRORTYPES.includes(data?.errorCodes)) return;
        if ((typeof data === 'string' && data.indexOf('Error') > -1)) {
          throw "Request Failed";
        } else {
          _isSubmitting(false);
          Store.addNotification({ ...constants.SUCCESSTOAST, message: "Draft Saved" });
        }
      })
      .catch((err) => {
        _isSubmitting(false);
        console.error(err);
        Store.addNotification({ ...constants.ERRORTOAST, message: "Request failed" });
      });
  };

  const handleAddCrewMemberSave = (crewMemberObj) => {
    if (crewMemberObj) {
      _fsr(old => ({
        ...old,
        data: {
          ...old?.data,
          crewMembers: [...old?.data?.crewMembers, crewMemberObj?.id]
        }
      }));
    }
    handleCloseAddCrewMemberModal();
    getCrewMembersByFleet();
  };

  const handleConfirmDiscardDataStepBack = () => {
    getFsrDetails();
    handleBackStep();
    handleCloseConfirmStepBackModal();
  };

  const handleOpenAddCrewMemberModal = (inputText) => {
    _showAddCrewMemberModal(true);
  };

  const handleCloseAddCrewMemberModal = () => {
    _showAddCrewMemberModal(false);
  };

  const handleFlightPlanUpload = (e) => {
    if (verifyFileSizeLessThanOneMb(e.target.files[0].size)) {
      _hasUserUploadedNewFile(true);
      _fsr(old => ({
        ...old,
        data: {
          ...old?.data,
          flight_plan_file: e.target.files[0],
          flight_plan_file_name: e.target.files[0]?.name
        }
      }));
    }
    else Store.addNotification({ ...constants.ERRORTOAST, message: 'File size should be less than 1 MB' });
  };

  const handleReplicateLeg = (leg, legIndex) => {
    let requestleg_requestLeg = [...fsr?.data?.requestleg_requestLeg];
    for (let index = 0; index <= requestleg_requestLeg?.length - 1; index++) {
      if (index > legIndex) {
        const edittedLeg = { ...fsr?.data?.requestleg_requestLeg[index] };
        edittedLeg.leg_type = leg?.leg_type;
        edittedLeg.pax_count = leg?.pax_count;
        edittedLeg.requestlegservice_services = leg?.requestlegservice_services?.map(s => ({ service: s?.service, company: s?.company, quantity: s?.quantity }));
        edittedLeg.requestlegcrewmember_crewMembers = leg?.requestlegcrewmember_crewMembers?.map(c => ({ crewmember: c?.crewmember }));
        edittedLeg.requestlegcatering_requestLegCatering = leg?.requestlegcatering_requestLegCatering?.slice()?.map(c => {
          const newCatering = { ...c };
          delete newCatering.id;
          delete newCatering.version;
          delete newCatering.requestLeg;
          return newCatering;
        });
        edittedLeg.reqlegtransport_requestLegTransport = leg?.reqlegtransport_requestLegTransport?.slice()?.map(t => {
          const newTransport = { ...t };
          delete newTransport.id;
          delete newTransport.version;
          delete newTransport.requestLeg;
          return newTransport;
        });
        if (index >= requestleg_requestLeg?.length - 1) {
          edittedLeg.requestleghotel_requestLegHotel = [];
          edittedLeg.reqlegcarrental_requestLegCarRental = [];
        } else {
          edittedLeg.requestleghotel_requestLegHotel = leg?.requestleghotel_requestLegHotel?.slice()?.map(h => {
            const newHotel = { ...h };
            newHotel.id = generateUID('hotel_');
            newHotel.checkInDateTime = requestleg_requestLeg[index + 1]
              ? dayjs(edittedLeg?.arrival_date)?.format('YYYY-MM-DD')
              : dayjs()?.format('YYYY-MM-DD');
            newHotel.checkOutDateTime = requestleg_requestLeg[index + 1]
              ? dayjs(requestleg_requestLeg[index + 1]?.departure_date)?.format('YYYY-MM-DD')
              : dayjs()?.format('YYYY-MM-DD');
            newHotel.requestLegHotelPAX = newHotel?.requestLegHotelPAX?.map(pax => {
              const newPAX = { ...pax };
              delete newPAX.id;
              delete newPAX.version
              delete newPAX.requestLegHotel;
              return newPAX;
            });
            delete newHotel.version;
            delete newHotel.requestLeg;
            return newHotel;
          });
          edittedLeg.reqlegcarrental_requestLegCarRental = leg?.reqlegcarrental_requestLegCarRental?.slice()?.map(cr => {
            const newCarRental = { ...cr };
            newCarRental.pick_up_date = requestleg_requestLeg[index + 1]
              ? dayjs(edittedLeg?.arrival_date)?.format('YYYY-MM-DD')
              : dayjs()?.format('YYYY-MM-DD');
            newCarRental.drop_off_date = requestleg_requestLeg[index + 1]
              ? dayjs(requestleg_requestLeg[index + 1]?.departure_date)?.format('YYYY-MM-DD')
              : dayjs()?.format('YYYY-MM-DD');
            newCarRental.rental_days = dayjs(newCarRental?.drop_off_date).diff(dayjs(newCarRental?.pick_up_date), 'day') + 1;
            delete newCarRental.id;
            delete newCarRental.version;
            delete newCarRental.requestLeg;
            return newCarRental;
          });
        }
        requestleg_requestLeg[index] = edittedLeg;
      }
    }
    _fsr(old => ({
      ...old,
      data: {
        ...old.data,
        requestleg_requestLeg
      }
    }));
    Store.addNotification({ ...constants.SUCCESSTOAST, message: "Leg settings copied to next legs" });
  };

  const handleReplicateLegCrewOnly = (leg, legIndex) => {
    let requestleg_requestLeg = [...fsr?.data?.requestleg_requestLeg];
    for (let index = 0; index <= requestleg_requestLeg?.length - 1; index++) {
      if (index > legIndex) {
        const edittedLeg = { ...fsr?.data?.requestleg_requestLeg[index] };
        edittedLeg.requestlegcrewmember_crewMembers = leg?.requestlegcrewmember_crewMembers?.map(c => ({ crewmember: c?.crewmember }));
        edittedLeg.requestleghotel_requestLegHotel = edittedLeg?.requestleghotel_requestLegHotel?.map(h => {
          const legHotel = { ...h };
          legHotel.requestleghotelpax_requestLegHotelPAX = [];
          // legHotel.requestLegHotelPAX = edittedLeg.requestlegcrewmember_crewMembers?.map(c => ({ crewMembers: c?.crewmember }));
          return legHotel;
        })
        requestleg_requestLeg[index] = edittedLeg;
      }
    }
    _fsr(old => ({
      ...old,
      data: {
        ...old.data,
        requestleg_requestLeg
      }
    }));
    Store.addNotification({ ...constants.SUCCESSTOAST, message: "Leg crew copied to next legs" });
  };

  const handleDuplicateCrewMembers = (legId, toLegs) => {
    let requestleg_requestLeg = [...fsr?.data?.requestleg_requestLeg];
    const leg = requestleg_requestLeg?.find(L => L?.id == legId);
    requestleg_requestLeg.forEach(L => {
      if (L?.id != legId && toLegs?.indexOf(L?.id) > -1) {
        L.requestlegcrewmember_crewMembers = leg?.requestlegcrewmember_crewMembers?.map(c => ({ crewmember: c?.crewmember }));
        L.requestleghotel_requestLegHotel = L?.requestleghotel_requestLegHotel?.map(h => {
          const legHotel = { ...h };
          legHotel.requestleghotelpax_requestLegHotelPAX = [];
          // legHotel.requestLegHotelPAX = edittedLeg.requestlegcrewmember_crewMembers?.map(c => ({ crewMembers: c?.crewmember }));
          return legHotel;
        });
      }
    });
    _fsr(old => ({
      ...old,
      data: {
        ...old.data,
        requestleg_requestLeg
      }
    }));
    handleCloseDuplicateCrewMemberModal();
    Store.addNotification({ ...constants.SUCCESSTOAST, message: "Leg crew copied to selected legs" });
  };

  const handleOpenDuplicateCrewMemberModal = (legId) => {
    _selectedDuplicateLegId(legId);
    _showDuplicateCrewMemberModal(true);
  };

  const handleCloseDuplicateCrewMemberModal = () => {
    _selectedDuplicateLegId(null);
    _showDuplicateCrewMemberModal(false);
  };

  const handleOpenConfirmRemoveLegModal = () => {
    _showConfirmRemoveLegModal(true);
  };

  const handleCloseConfirmRemoveLegModal = () => {
    _selectedDeletingLegId(null);
    _showConfirmRemoveLegModal(false);
  };

  const handleOpenConfirmStepBackModal = () => {
    _showConfirmStepBackModal(true);
  };

  const handleCloseConfirmStepBackModal = () => {
    _showConfirmStepBackModal(false);
  };

  const handleAllowLegEdit = (legId) => {
    _allowedEditableLegs(old => [...old, legId]);
    handleCloseAllowEditLegModal();
  };

  const handleOpenEditLegModal = (leg) => {
    if (leg && leg?.id)
      _selectedLeg(leg);
  };

  const handleCloseEditLegModal = () => {
    _selectedLeg(null);
  };

  const handleOpenAllowEditLegModal = (legId) => {
    _showAllowEditLegModal(true);
    _allowEditLegId(legId);
  };

  const handleCloseAllowEditLegModal = () => {
    _showAllowEditLegModal(false);
    _allowEditLegId(null);
  };

  // *********** Render Functions ***********

  const REQUEST_PAGINATION = () => (
    <div className="request-pagination">
      <div className={`request-pagination__item ${fsrSteps?.currentStep === 1 ? 'active' : ''}`}>
        <div className="request-pagination__num">1</div>
        <div className="request-pagination__wrap">
          <div className="request-pagination__title">Request Entry</div>
        </div>
      </div>
      <div className={`request-pagination__item ${fsrSteps?.currentStep === 2 ? 'active' : ''}`}>
        <div className="request-pagination__num">2</div>
        <div className="request-pagination__wrap">
          <div className="request-pagination__title">Leg Setup</div>
        </div>
      </div>
      <div className={`request-pagination__item ${fsrSteps?.currentStep === 3 ? 'active' : ''}`}>
        <div className="request-pagination__num">3</div>
        <div className="request-pagination__wrap">
          <div className="request-pagination__title">Review and Send</div>
        </div>
      </div>
    </div>
  );

  const ADD_LEG_BUTTON = () => (
    <div className="w3-row-padding">
      {
        fsr &&
          fsr?.data?.requestleg_requestLeg &&
          !fsr?.data?.requestleg_requestLeg?.length
          ? <div className="w3-col l12 m12 s12 text-center">
            <div onClick={handleAddNewLeg}
              className={`${fsr?.errors?.requestleg_requestLeg ? 'error-field' : 'non'} w-full p-2 w3-border cursor-pointer border-thick border-dotted ${fsr?.data?.fleet ? '' : 'cursor-disable'}`}
            >
              + Add First Leg
            </div>
          </div>
          : <div className="w3-col l12 m12 s12 text-right">
            <button
              onClick={handleAddNewLeg}
              disabled={
                fsr?.data?.status?.toLocaleLowerCase() === 'cancelled' ||
                fsr?.data?.status?.toLocaleLowerCase() === 'completed' ||
                fsr?.data?.status?.toLocaleLowerCase() === 'locked' ||
                fsr?.data?.requestleg_requestLeg.find(leg => (
                  !leg?.departure_airport_iata ||
                  !leg?.destination_airport_iata ||
                  !leg?.departure_date ||
                  !leg?.departure_time ||
                  !leg?.arrival_date ||
                  !leg?.arrival_time
                ))
              }
              className={`w3-btn bg-primary-blue w3-text-white small-top-margin small-bottom-margin`}
            >
              Add Leg
            </button>
          </div>
      }
    </div>
  );

  const REQUEST_LEGS = () => (
    <div id='Fsr-Legs' className="w3-row-padding">
      {fsr &&
        fsr?.data?.requestleg_requestLeg?.length
        ? fsr?.data?.requestleg_requestLeg?.map((Leg, index) => (
          <ul
            key={index}
            className={`
            relative 
            list-request
            ${Leg?.is_local ? 'w3-pale-green' : ''}
            ${!Leg?.is_local && Leg?.departure_airport_iata && Leg?.destination_airport_iata ? 'w3-pale-yellow' : ''}
            `}
          >
            <li>
              <div>
                <label> From </label>
                <AirportSelect
                  disabled={
                    Boolean(Leg?.departure_airport_iata && fsr?.data?.requestleg_requestLeg[index - 1]?.destination_airport_iata)
                  }
                  inputClass={`w3-border w3-round ${fsr?.errors?.legs?.[Leg.id]?.departure_airport_iata ? 'error-field' : ''}`}
                  containerClass="block small-top-margin small-bottom-margin"
                  defaultValue={
                    Leg?.departure_airport_iata &&
                    `${Leg?.departure?.airport_code?.[0]} / ${Leg?.departure_airport_iata}`
                  }
                  handleOptionSelect={(iata, icao, airportObj) =>
                    handleDepartureChange(Leg.id, iata, airportObj)
                  }
                  getFirstAirportOnEnterKey={true}
                />
              </div>
            </li>
            <li>
              <label> Type </label>
              <select
                value={Leg?.departure_airport_type}
                onChange={(e) => handleChangeLeg(Leg.id, "departure_airport_type", e.target.value)}
                className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
              >
                <option value='Civilian'>Civilian</option>
                <option value='Military'>Military</option>
                <option value='Royal'>Royal</option>
              </select>
            </li>
            <li>
              <div>
                <label> To </label>
                <AirportSelect
                  inputClass={`w3-border w3-round ${fsr?.errors?.legs?.[Leg.id]?.destination_airport_iata ? 'error-field' : ''}`}
                  containerClass="block small-top-margin small-bottom-margin"
                  defaultValue={
                    Leg?.destination_airport_iata &&
                    `${Leg?.destination?.airport_code?.[0]} / ${Leg?.destination_airport_iata}`
                  }
                  handleOptionSelect={(iata, icao, airportObj) =>
                    handleDestinationChange(Leg.id, iata, airportObj)
                  }
                  getFirstAirportOnEnterKey={true}
                />
              </div>
            </li>
            <li>
              <label> Type </label>
              <select
                value={Leg?.arrival_airport_type}
                onChange={(e) => handleChangeLeg(Leg.id, "arrival_airport_type", e.target.value)}
                className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
              >
                <option value='Civilian'>Civilian</option>
                <option value='Military'>Military</option>
                <option value='Royal'>Royal</option>
              </select>
            </li>
            <li>
              <div>
                <label> Dep Date </label>
                <input
                  type="date"
                  value={Leg?.departure_date}
                  min={
                    index !== 0
                      ? dayjs(
                        `${fsr?.data?.requestleg_requestLeg[index - 1]?.arrival_date} ${fsr?.data?.requestleg_requestLeg[index - 1]?.arrival_time}`
                      ).format('YYYY-MM-DD')
                      : dayjs().format('YYYY-MM-DD')
                  }
                  style={
                    invalidLegs?.find(L => L?.id === Leg.id)?.type === 'departure_date'
                      ? { background: '#E6D3D3' }
                      : {}
                  }
                  onChange={(e) => handleChangeLeg(Leg.id, "departure_date", e.target.value)}
                  onBlur={(e) => handleChangeLeg(Leg.id, "departure_date", e.target.value, e)}
                  className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                />
              </div>
            </li>
            <li>
              <div>
                <label> Dep Time </label>
                <input
                  type="text"
                  maxLength={5}
                  placeholder="00:00"
                  value={Leg?.departure_time || ''}
                  style={
                    invalidLegs?.find(L => L?.id === Leg.id)?.type === 'departure_date'
                      ? { background: '#E6D3D3' }
                      : {}
                  }
                  onBlur={(e) => handleChangeLeg(Leg.id, "departure_time", dayjs(`${Leg?.departure_date || '2001-01-01'} ${e.target.value}`).format('HH:mm'), e)}
                  onChange={(e) => handleChangeLeg(Leg.id, "departure_time", e.target.value?.replace(/[a-zA-Z]/g, ''))}
                  className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                />
              </div>
            </li>
            <li>
              <div>
                <label> Arrival Date </label>
                <input
                  type="date"
                  value={Leg?.arrival_date}
                  min={Leg?.departure_date}
                  style={
                    invalidLegs?.find(L => L?.id === Leg.id)?.type === 'arrival_date'
                      ? { background: '#E6D3D3' }
                      : {}
                  }
                  onBlur={(e) => handleChangeLeg(Leg.id, "arrival_date", e.target.value, e)}
                  onChange={(e) => handleChangeLeg(Leg.id, "arrival_date", e.target.value)}
                  className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                />
              </div>
            </li>
            <li>
              <div>
                <label> Arrival Time </label>
                <input
                  type="text"
                  maxLength={5}
                  placeholder="00:00"
                  value={Leg?.arrival_time || ''}
                  style={
                    invalidLegs?.find(L => L?.id === Leg.id)?.type === 'arrival_date'
                      ? { background: '#E6D3D3' }
                      : {}
                  }
                  onBlur={(e) => handleChangeLeg(Leg.id, "arrival_time", dayjs(`${Leg?.arrival_date || '2001-01-01'} ${e.target.value}`).format('HH:mm'), e)}
                  onChange={(e) => handleChangeLeg(Leg.id, "arrival_time", e.target.value?.replace(/[a-zA-Z]/g, ''), e)}
                  className="w3-input w3-border w3-round small-top-margin small-bottom-margin"
                />
              </div>
            </li>
            {index !== 0
              ? <li>
                <div onClick={e => handleRemoveLegPrecheck(Leg?.id)} className="small-top-margin small-bottom-margin">
                  <input id={`save-${Leg.id}`} className="w3-button no-opacity p-0" type='button' />
                  <label className='w3-button w3-pale-red' htmlFor={`remove-${Leg.id}`}>
                    <X className='w-5 h-5 w3-text-red' />
                  </label>
                </div>
              </li>
              : <li><div className='w3-button no-opacity cursor-default'> <X className='w-5 h-5 w3-text-red' /> </div></li>
            }
            {requestId && parseInt(Leg?.id) && !allowedEditableLegs?.includes(Leg?.id)
              ? <li className='absolute w-full h-full z-50 cursor-disable text-right'>
                <Edit
                  onClick={e => handleOpenAllowEditLegModal(Leg?.id)}
                  className='w-5 h-5 z-100 cursor-pointer'
                />
              </li>
              : null
            }
          </ul>
        ))
        : null
      }
    </div>
  );

  const FSR_DETAILS = () => (
    <React.Fragment>
      {/* <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Portal Id </label>
          <input
            type="text"
            value={fsr?.data?.portal_id}
            maxLength={25}
            placeholder="Optional"
            className='w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin'
            onChange={(e) => {
              handleFsrFieldChange("portal_id", e.target.value)
            }}
          />
        </div>
      </div> */}

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Flight No. </label>
          <input
            type="text"
            // disabled={requestId}
            value={fsr?.data?.flight_number}
            maxLength={8}
            className="w3-input w3-white w3-border w3-round small-top-margin small-bottom-margin"
            // className={`${INPUT_FIELD_CLASS} ${fsr?.errors?.flight_number ? 'error-field' : ''}`}
            onChange={(e) => {
              // Dont allow special characters in Flight No.
              const value = e.target.value?.match(/[A-Za-z0-9]+/)?.join() || '';
              handleFsrFieldChange("flight_number", value)
            }}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Purpose of Flight </label>
          <select
            disabled={requestId}
            value={fsr?.data?.purpose_of_flight}
            className={`${INPUT_FIELD_CLASS} ${fsr?.errors?.purpose_of_flight ? 'error-field' : ''}`}
            onChange={(e) => handleFsrFieldChange("purpose_of_flight", e.target.value)}
          >
            <option value={""} disabled > Select type </option>
            <option value='OPS'> OPS </option>
            <option value='FAM'> FAM </option>
            <option value='Training'> Training </option>
            <option value='Maintenance'> Maintenance </option>
            <option value='Test Flight'> Test Flight </option>
            <option value='Test Flight Plan'> Test Flight Plan </option>
          </select>
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Airbase </label>
          <select
            value={fsr?.data?.airbase}
            disabled={requestId || !loggedUserInfo?.data?.is_staff}
            onChange={(e) => handleAirbaseChange("airbase", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${fsr?.errors?.airbase ? 'error-field' : ''}`}
          >
            <option value={""} disabled > Select Airbase </option>
            {airbases &&
              airbases?.data &&
              airbases?.data?.map((A, index) => (
                <option key={index} value={A.id}> {A.name} </option>
              ))}
          </select>
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Fleet </label>
          <select
            value={fsr?.data?.fleet}
            disabled={requestId || fsr?.data?.airbase === "" || !loggedUserInfo?.data?.is_staff}
            onChange={(e) => handleFleetChange("fleet", e.target.value)}
            className={`${INPUT_FIELD_CLASS} ${fsr?.errors?.fleet ? 'error-field' : ''}`}
          >
            <option value={""} disabled> Select Fleet </option>
            {fleet &&
              fleet?.data &&
              fleet?.data
                ?.filter((F) => F.airbase == fsr?.data?.airbase)
                ?.map((F, index) => (
                  <option value={F.id} key={index}> {F.name} </option>
                ))}
          </select>
        </div>
      </div>

      <div className="w3-row-padding">
        <div className="w3-col l6 m6 s12">
          <label> Aircraft </label>
          <Select
            placeholder="Select aircraft"
            value={
              aircrafts &&
              aircrafts?.data
                ?.filter((A) => A.fleet == fsr?.data?.fleet && A.id == fsr?.data?.aircraft)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            options={
              fleet &&
              aircrafts &&
              fleet?.data &&
              aircrafts?.data &&
              aircrafts?.data
                ?.filter((A) => A.fleet == fsr?.data?.fleet)
                .map((A) => ({ label: A.tailnumber, value: A.id }))
            }
            onChange={(option) =>
              handleFsrFieldChange("aircraft", option.value)
            }
            className={`${fsr?.errors?.aircraft ? 'error-field' : ''} w-full small-top-margin small-bottom-margin`}
          />
        </div>
        <div className="w3-col l6 m6 s12">
          <label> Urgent Request </label>
          <select
            disabled={requestId}
            value={fsr?.data?.urgent_request}
            onChange={(e) =>
              handleFsrFieldChange("urgent_request", e.target.value)
            }
            className={`${INPUT_FIELD_CLASS}`}
          >
            <option value={true}> Yes </option>
            <option value={false}> No </option>
          </select>
        </div>
      </div>
    </React.Fragment>
  );

  const CREW_MEMBERS = () => (
    <CreatableSelect
      placeholder='Select crew members'
      className='w-full small-top-margin small-bottom-margin'
      isMulti={true}
      isDisabled={requestId || !fsr?.data?.fleet}
      onChange={c => handleCrewMembersChange(c)}
      onCreateOption={handleOpenAddCrewMemberModal}
      options={crewMembers && crewMembers?.data?.map((c) => ({ label: `${c.crewname} (${c?.passport || 'N/A'}) (${c?.saudia_id || 'N/A'})`, value: c }))}
      value={crewMembers && crewMembers?.data?.map((c) => {
        if (fsr?.data?.crewMembers?.includes(c.id))
          return ({ label: `${c.crewname} (${c?.passport || 'N/A'}) (${c?.saudia_id || 'N/A'})`, value: c });
        return null;
      })}
    />
  );

  const STEP_ONE = () => (
    <React.Fragment>
      <div className="section w-full relative">
        <span className="heading"> Request Details</span>
        <div className="border-primary-blue w3-round p-2">
          {FSR_DETAILS()}
        </div>
      </div>
      <div className="section w-full relative">
        <span className="heading"> Crew Members </span>
        <div className="flex border-primary-blue w3-round p-2">
          {CREW_MEMBERS()}
        </div>
      </div>
      <div className="section w-full relative">
        <span className="heading"> Request Legs </span>
        <div className="border-primary-blue w3-round p-2">
          {REQUEST_LEGS()}
          {ADD_LEG_BUTTON()}
        </div>
      </div>
    </React.Fragment>
  );

  const STEP_TWO = () => (
    <React.Fragment>
      {REQUEST_LEG_DETAILS()}
    </React.Fragment>
  );

  const STEP_THREE = () => (
    <RequestView
      fsrData={fsr?.data}
      isFieldInvalid={isStep3Invalid}
      changeHandler={e => handleFsrFieldChange(e.target.name, e.target.value)}
    />
  );

  const FORM_FOOTER = () => (
    <React.Fragment>
      <div className="justify-between flex flex-wrap">
        {
          fsrSteps?.currentStep !== 1
            ? <button
              disabled={isSubmitting}
              onClick={e => (
                fsrSteps?.currentStep === 2
                  ? handleOpenConfirmStepBackModal()
                  : handleBackStep()
              )
              }
              className="w3-btn bg-primary-blue w3-text-white small-right-margin"
            >
              Back
            </button>
            : null
        }
        {
          fsr?.data?.status?.toLocaleLowerCase() === 'cancelled' ||
            fsr?.data?.status?.toLocaleLowerCase() === 'completed' ||
            fsr?.data?.status?.toLocaleLowerCase() === 'locked'
            ? <span> This request is {fsr?.data?.status?.toLowerCase()} </span>
            : null
        }
        {fsrSteps?.currentStep == 3 &&
          <button
            disabled={isSubmitting}
            onClick={handleSaveDraft}
            className="w3-btn bg-primary-blue w3-text-white ml-auto"
          >
            Draft
          </button>
        }
        <button
          disabled={
            isSubmitting ||
            fsr?.data?.status?.toLocaleLowerCase() === 'cancelled' ||
            fsr?.data?.status?.toLocaleLowerCase() === 'completed' ||
            fsr?.data?.status?.toLocaleLowerCase() === 'locked'
          }
          onClick={handleNextStep}
          className="w3-btn bg-primary-blue w3-text-white ml-auto"
        >
          {
            fsrSteps?.currentStep == 1
              ? isSubmitting
                ? "Submitting"
                : "Submit & Continue"
              : null
          }
          {
            fsrSteps?.currentStep == 2 ? "Review Request" : null
          }
          {
            fsrSteps?.currentStep == 3 ? "Confirm & Send" : null
          }
        </button>
      </div>
      <div className="h-2"></div>
    </React.Fragment>
  );

  const EDIT_LEG_MODAL = () => (
    <>
      {services && services?.data && crewMembers && crewMembers?.data && selectedLeg
        ? <LegSetupModal
          leg={selectedLeg}
          legs={fsr?.data?.requestleg_requestLeg}
          isOpen={Boolean(selectedLeg?.id)}
          closeModal={handleCloseEditLegModal}
          handleSaveUpdatedLeg={handleSaveUpdatedLeg}
          services={services?.data}
          crewMembers={crewMembers?.data}
          fleet={fleet}
          selectedFleet={fsr?.data?.fleet}
          handleOpenAddCrewMemberModal={handleOpenAddCrewMemberModal}
          getCrewMembersByFleet={getCrewMembersByFleet}
          modalClass="w-custom-modal w3-round-medium"
        />
        : null
      }
    </>
  );

  const LEG_DELETE_CONFIRMATION_MODAL = () => (
    <>
      <LegDeleteConfirmationModal
        legs={fsr?.data?.requestleg_requestLeg}
        isSubmitting={isSubmitting}
        handleRemoveLeg={handleRemoveLeg}
        selectedDeletingLegId={selectedDeletingLegId}
        showConfirmRemoveLegModal={showConfirmRemoveLegModal}
        handleCloseConfirmRemoveLegModal={handleCloseConfirmRemoveLegModal}
      />
    </>
  );

  const STEP_BACK_CONFIRMATION_MODAL = () => (
    <>
      <StepbackConfirmationModal
        isSubmitting={isSubmitting}
        showConfirmStepBackModal={showConfirmStepBackModal}
        handleCloseConfirmStepBackModal={handleCloseConfirmStepBackModal}
        handleConfirmDiscardDataStepBack={handleConfirmDiscardDataStepBack}
      />
    </>
  );

  const ADD_CREWMEMBER_MODAL = () => (
    <>
      {showAddCrewMemberModal &&
        <CreateCrewMemberModal
          isOpen={showAddCrewMemberModal}
          fleet={fleet}
          selectedFleet={fsr?.data?.fleet}
          onCrewMemberCreate={handleAddCrewMemberSave}
          handleCloseAddCrewMemberModal={handleCloseAddCrewMemberModal}
        />
      }
    </>
  );

  const EDIT_LEG_CONFIRMATION_MODAL = () => (
    <>
      {showAllowEditLegModal &&
        <AllowEditLegConfirmModal
          showAllowEditLegModal={showAllowEditLegModal}
          selectedLegId={allowEditLegId}
          handleAllowLegEdit={handleAllowLegEdit}
          handleCloseAllowEditLegModal={handleCloseAllowEditLegModal}

        />
      }
    </>
  );

  const DUPLICATE_CREW_MEMBER_MODAL = () => (
    <>
      {showDuplicateCrewMemberModal &&
        <DuplicateCrewMemberModal
          legs={fsr?.data?.requestleg_requestLeg}
          showDuplicateCrewMemberModal={showDuplicateCrewMemberModal}
          selectedDuplicateLegId={selectedDuplicateLegId}
          handleDuplicateCrewMembers={handleDuplicateCrewMembers}
          handleCloseDuplicateCrewMemberModal={handleCloseDuplicateCrewMemberModal}
        />
      }
    </>
  );

  const REQUEST_LEG_DETAILS = () => (
    fsr?.data?.requestleg_requestLeg.map((L, index) => (
      <div className="section w-full relative" key={index} >
        <span className="heading heading-small-size">
          {
            `${L?.departure_airport_icao || L?.departure?.airport_code?.[0]}/${L?.departure_airport_iata} → 
          ${L?.destination_airport_icao || L?.destination?.airport_code?.[0]}/${L?.destination_airport_iata}`
          }
        </span>
        <div className="border-primary-blue w3-round p-2 flex flex-aligned-center justify-between wrap w3-card w3-hover-shadow">
          <div className="date-holder">
            {`${dayjs(L?.departure_date).format('DD/MM/YYYY')} 
            -
            ${dayjs(L?.arrival_date).format('DD/MM/YYYY')}`}
            <div className='w3-small flex flex-wrap'>
              <div className={`${L?.requestleghotel_requestLegHotel?.length ? 'small-right-margin' : ''}`}>
                {`${L?.requestleghotel_requestLegHotel?.length ? 'With Hotel,' : ''}`}
              </div>
              <div className={`${L?.requestlegcatering_requestLegCatering_from?.length || L?.requestlegcatering_requestLegCatering_to?.length ? 'small-right-margin' : ''}`}>
                {`${L?.requestlegcatering_requestLegCatering_from?.length || L?.requestlegcatering_requestLegCatering_to?.length ? 'With Catering,' : ''}`}
              </div>
              <div className={`${L?.reqlegtransport_requestLegTransport?.length ? 'small-right-margin' : ''}`}>
                {`${L?.reqlegtransport_requestLegTransport?.length ? 'With Transport' : ''}`}
              </div>
              <div className={`${L?.reqlegcarrental_requestLegCarRental?.length ? 'small-right-margin' : ''}`}>
                {`${L?.reqlegcarrental_requestLegCarRental?.length ? 'With Car Rental' : ''}`}
              </div>
            </div>
          </div>
          <div className="btn-holder">
            {/* {fsr?.data?.requestleg_requestLeg?.length > 1 && index != fsr?.data?.requestleg_requestLeg?.length - 1
              ? <button className="w3-btn bg-primary-blue w3-text-white small-right-margin" onClick={() => handleReplicateLeg(L, index)}>Duplicate Settings</button>
              : null
            } */}
            {contractServices?.length && fsr?.data?.id &&
              fsr?.data?.requestleg_requestLeg?.length > 1 &&
              index != fsr?.data?.requestleg_requestLeg?.length - 1
              ? <button className="w3-btn bg-primary-blue w3-text-white small-right-margin" onClick={() => handleOpenDuplicateCrewMemberModal(L?.id, index)}>Duplicate Crew Members</button>
              : null
            }
            {/* TODO: disable leg setup button if Leg departure is less than 30 minutes for non admin users */}
            {/* {approvedFsrList?.length && L?.departure_date + ' ' + L?.departure_time + "-" + dayjs(getCurrentGMTDateTime())?.format('YYYY-MM-DD HH:mm')} */}
            {
              isContractServicesLoaded && fsr?.data?.id
                ? <button className="w3-btn bg-primary-blue w3-text-white" onClick={() => handleOpenEditLegModal(L)}>Setup Leg</button>
                : <Loader spinnerClassName='w-8 h-8 text-primary-blue' />
            }
          </div>
        </div>
      </div>
    )
    )
  );

  const FLIGHT_PLAN_UPLOAD = () => (
    <FileUpload
      id='flight_plan_file'
      name='flight_plan_file'
      label='Upload Flight Plan'
      accept={'application/pdf,image/*'}
      isDownloadable={true}
      fileName={fsr?.data?.flight_plan_file_name}
      file={fsr?.data?.flight_plan_file}
      onChange={handleFlightPlanUpload}
      containerClass='small-top-margin'
      labelClass='w3-btn bg-primary-blue w3-text-white small-top-margin'
    />
  );

  const LOADER = () => (
    <div className="request-form-container">
      <div className="h-30 flex justify-center items-center">
        <div><Loader spinnerClassName='w-10 h-10 text-primary-blue' />
          <p className='text-primary-blue'> Loading data... </p>
        </div>
      </div>
    </div>
  );

  const REQUEST_FORM = () => (
    <div className="request-form-container p-0">
      <div className="section w-full relative">
        {REQUEST_PAGINATION()}
      </div>
      {isLoading && fsrSteps?.currentStep !== 2
        ? LOADER()
        : <React.Fragment>
          {fsrSteps?.currentStep === 1 ? STEP_ONE() : null}
          {fsrSteps?.currentStep === 2 ? STEP_TWO() : null}
          {fsrSteps?.currentStep === 3 ? STEP_THREE() : null}
          <div className="w-full py-2">
            {FORM_FOOTER()}
          </div>
        </React.Fragment>
      }
      {EDIT_LEG_MODAL()}
      {LEG_DELETE_CONFIRMATION_MODAL()}
      {STEP_BACK_CONFIRMATION_MODAL()}
      {ADD_CREWMEMBER_MODAL()}
      {EDIT_LEG_CONFIRMATION_MODAL()}
      {DUPLICATE_CREW_MEMBER_MODAL()}
      <div className='h-4'></div>
    </div>
  );

  return (
    <div id="Request-Form">
      {REQUEST_FORM()}
    </div>
  );
};

export default RequestForm;
